<template>
  <el-container>
    <el-header height="auto"><Header></Header></el-header>
    <el-main height="auto" style="overflow: hidden">
      <!-- 加入我们-->
      <div class="c-join-us">
        <div class="c-ju-wrap">
          <p>代理加盟@战略合作</p>
          <p>双宝新零售酷366版生态合作伙伴，全国火热招募中</p>
          <p>致力于与合作伙伴打造最新风口，携手共掘新零售行业万亿市场</p>
          <div class="c-juw-wrap">
            <div @click="dialogVisible=true">加入我们</div>
            <!--<div>前往管理后台</div>-->
          </div>
        </div>
      </div>
      
      <div class="c-yx-partner">战略合作伙伴</div>
      <!-- 合伙伙伴展示 -->
      <div class="c-partner-show">
        <div class="c-partner-show-box">
          <div>
            <img v-lazy="require('../../../assets/images/k366/shuang-bao-logo.jpg')" alt="泉州市双宝网络技术有限公司" height="172" />
          </div>
          <span>泉州市双宝网络技术有限公司</span>
        </div>
        <div class="c-partner-show-box">
          <div>
            <img v-lazy="require('../../../assets/images/k366/yun-qi-da-log.jpg')" alt="湖南云气达科技有限公司" height="172" />
          </div>
          <span>湖南云气达科技有限公司</span>
        </div>
        <div class="c-partner-show-box">
          <div>
            <img v-lazy="require('../../../assets/images/k366/shui-ji-shi-logo.png')" alt="水集市(福州)企业管理有限公司" height="172" />
          </div>
          <span>水集市(福州)企业管理有限公司</span>
        </div>
        <div class="c-partner-show-box">
          <div>
            <img v-lazy="require('../../../assets/images/k366/yun-zhu-logo.jpg')" alt="福建省安溪云竹茶业有限公司" height="172" />
          </div>
          <span>福建省安溪云竹茶业有限公司</span>
        </div>
      </div>
      <!-- 酷366合作伙伴 -->
      <div class="c-ybs-partner">
        <p>为什么要成为酷366合作伙伴</p>
        <div class="c-ybsp-img">
          <div>
            <img v-lazy="require('../../../assets/images/659.png')" alt="">
            <p>海量资源</p>
          </div>
          <div>
            <img v-lazy="require('../../../assets/images/657.png')" alt="">
            <p>海量资源</p>
          </div>
          <div>
            <img v-lazy="require('../../../assets/images/658.png')" alt="">
            <p>市场巨大</p>
          </div>
          <div>
            <img v-lazy="require('../../../assets/images/660.png')" alt="">
            <p>培训支持</p>
          </div>
        </div>
      </div>
      <div class="c-partner-msg">
        成为合作伙伴的条件
      </div>
      <!-- 合作伙伴条件 -->
      <div class="c-partner-condition">
        <div class="c-location-zl">
          <div class="c-outer-wrap">
            <div class="c-pc-wrap">
              <p>具备公司资质</p>
              <p>具有合法营业执照和独立法人代表，有良好的商业信誉和口碑</p>
            </div>
            <div class="c-ow-div">
            </div>
          </div>
        </div>
        <div class="c-location-zl">
          <div class="c-outer-wrap">
            <div class="c-pc-wrap">
              <p>具有销售团队</p>
              <p>专职销售团队，具有互联网或相关行业的代理或销售经营背景</p>
            </div>
            <div class="c-ow-div">
            </div>
          </div>
        </div>

        <div class="c-location-zl">
          <div class="c-outer-wrap">
            <div class="c-pc-wrap">
              <p>具有客户资源</p>
              <p>拥有当地丰富的企业级资源或线下门店资源</p>
            </div>
            <div class="c-ow-div">
            </div>
          </div>
        </div>

        <div class="c-location-zl">
          <div class="c-outer-wrap">
            <div class="c-pc-wrap">
              <p>认可双宝网络文化</p>
              <p>认同双宝网络的产品价值和文化理念，愿意跟双宝网络长期合作</p>
            </div>
            <div class="c-ow-div">
            </div>
          </div>
        </div>
      </div>

      <div class="c-partner-msg">
        合作伙伴的申请流程
      </div>
      <!-- 合作伙伴申请流程 -->
      <div class="c-process-wrap">
        <img v-lazy="require('../../../assets/images/1.png')" alt="">
        <div class="c-pw-line"></div>
        <img v-lazy="require('../../../assets/images/2.png')" alt="">
        <div class="c-pw-line"></div>
        <img v-lazy="require('../../../assets/images/3.png')" alt="">
        <div class="c-pw-line"></div>
        <img v-lazy="require('../../../assets/images/4.png')" alt="">
        <div class="c-pw-line"></div>
        <img v-lazy="require('../../../assets/images/5.png')" alt="">
      </div>
      <div class="c-process-wrap2">
        <span>注册/登录</span>
        <span>提交申请</span>
        <span>合作洽谈</span>
        <span>预存款项</span>
        <span>签订合作协议</span>

      </div>
      <div class="c-sq2" @click="dialogVisible=true">
        立即申请
      </div>
      <div class="c-connect-jl">
        <div class="c-cjl" @click="dialogVisible=true">
          咨询渠道经理
        </div>
      </div>
      <div class="c-partner-msg2">
        酷366商城配送管理系统.授权代理商的认证查询
      </div>

      <!--<div class="c-agent-search">-->
      <!--<input type="text" placeholder="请输入代理商名字">-->
      <!--<div>搜索</div>-->
      <!--</div>-->

      <!-- 双宝网络和合作伙伴信息 -->
      <div class="c-zhai-zhi-she-msg">
        <div class="c-zz-she">
          <img v-lazy="require('../../../assets/images/k366/shuang-bao-logo.jpg')" height="100" />
        </div>
        <div class="c-zz-she2">
          <p>泉州市双宝网络技术有限公司【研发团队】</p>
          <p>泉州市双宝网络技术有限公司成立于2011年05月09日。软件开发；软件销售；信息系统集成服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动） 许可项目：食品经营（销售预包装食品）；</p>
          <p>
            <span>省级代理合作伙伴</span>
            <span>区域授权服务中心</span>
          </p>
        </div>
        <div class="c-zz-she3">
          <p>联系方式</p>
          <!--                <p>电话：(0592) 5793363  </p>-->
          <p>邮箱：kerter@qq.com</p>
          <p><router-link to="">官方网站</router-link></p>
        </div>
      </div>
      <div class="c-zhai-zhi-she-msg">
        <div class="c-zz-she">
          <img v-lazy="require('../../../assets/images/k366/yun-qi-da-log.jpg')" height="100" />
        </div>
        <div class="c-zz-she2">
          <p>湖南云气达科技有限公司【研发团队】</p>
          <p>经营范围包括应用、基础、支撑的软件开发；软件服务；信息科技技术咨询；信息科技技术服务；信息科技技术转让；信息技术咨询服务；信息科技技术的开发；软件技术转让；软件技术服务；代办燃气钢瓶检测服务；瓶装燃气（液化石油气）经营；城市配送；软件互联网销售；燃气灶具、洗碗机、城镇燃气、日用品、灶具及配件的销售；燃气设备租赁；燃气灶具、洗碗机安装；贸易咨询服务；物流信息服务；饮用水批发；饮用水、化工产品的零售；道路危险货物运输。（依法须经批准的项目，经相关部门批准后方可开展经营活动，未经批准不得从事P2P网贷、股权众筹、互联网保险、资管及跨界从事金融、第三方支付、虚拟货币交易、ICO、非法外汇等互联网金融业务）</p>
          <p>
            <span>省级代理合作伙伴</span>
            <span>区域授权服务中心</span>
          </p>
        </div>
        <div class="c-zz-she3">
          <p>联系方式</p>
          <!--                <p>电话：(0592) 5793363  </p>-->
          <p>邮箱：--</p>
          <p><router-link to="">官方网站</router-link></p>
        </div>
      </div>
      <div class="c-zhai-zhi-she-msg">
        <div class="c-zz-she">
          <img v-lazy="require('../../../assets/images/k366/shui-ji-shi-logo.png')" height="100" />
        </div>
        <div class="c-zz-she2">
          <p>水集市(福州)企业管理有限公司【战略合作】</p>
          <p>经营范围包括一般项目：企业管理；企业管理咨询；信息咨询服务（不含许可类信息咨询服务）；食品销售（仅销售预包装食品）。</p>
          <p>
            <span>省级代理合作伙伴</span>
            <span>区域授权服务中心</span>
          </p>
        </div>
        <div class="c-zz-she3">
          <p>联系方式</p>
          <!--                <p>电话：(0592) 5793363  </p>-->
          <p>邮箱：--</p>
          <p><router-link to="">官方网站</router-link></p>
        </div>
      </div>
      <div class="c-zhai-zhi-she-msg">
        <div class="c-zz-she">
          <img v-lazy="require('../../../assets/images/k366/yun-zhu-logo.jpg')" height="100" />
          <!--              <span>战略合作</span>-->
        </div>
        <div class="c-zz-she2">
          <p>福建省安溪云竹茶业有限公司【战略合作】</p>
          <p>经营范围包含：茶叶、茶食品、茶饮料、茶具、茶叶包装用品销售。（依法须经批准的项目，经相关部门批准后方可开展经营活动）</p>
          <p>
            <span>省级代理合作伙伴</span>
            <span>区域授权服务中心</span>

          </p>
        </div>
        <div class="c-zz-she3">
          <p>联系方式</p>
          <!--                <p>电话：(0592) 5793363  </p>-->
          <p>邮箱：sbstudio@foxmail.com</p>
          <p><router-link to="">官方网站</router-link></p>
        </div>
      </div>
      <!-- 联系我们 -->
      <ContactUs></ContactUs>
    </el-main>
    <el-footer height="auto">
      <Bottom ref="bottom"></Bottom>
    </el-footer>
  </el-container>
</template>

<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    import ContactUs from '@/components/Pc/ContactUs';

    export default {
        name: "AgentJoining",
        components:{Header, Bottom, ContactUs},
        data() {
            return {
                dialogVisible: false
            }
        },

        methods:{
        }
    }
</script>
<style scoped>
    .el-header, .el-main, .el-footer{
      padding: 0;
    }
    .el-main .block-box{
      margin: 0 auto;
      padding: 0 10%;
    }
    .el-main .c-join-us{
      width: 100%;
      height: 37.5rem;
      background-size: cover;
      background-image: url('../../../assets/images/agent.jpg');
      opacity: 0.95;
    }
    .el-main .c-join-us .c-ju-wrap{
      width: 50%;
      height: auto;
      margin: 0 auto;
      color: #ffffff;
      font-size: 1.5rem;
      text-align: center;
    }
    .el-main .c-join-us .c-ju-wrap>p{
      display: inline-block;
      width: 100%;
      font-size: 2rem;
      padding-bottom: 2.5rem;
    }
    .el-main .c-join-us .c-ju-wrap>p:first-child{
      font-size: 2.25rem;
      padding-top: 7rem;
    }
    .el-main .c-join-us .c-ju-wrap .c-juw-wrap{
      margin-top: 2rem;
    }
    .el-main .c-join-us .c-ju-wrap .c-juw-wrap>div{
      width: 8.44rem;
      height: 3.06rem;
      margin: 0 auto;
      color: #ffffff;
      text-align: center;
      line-height: 3.06rem;
      cursor: pointer;
    }
    .el-main .c-join-us .c-ju-wrap .c-juw-wrap>div:nth-child(1){
      background-color: #3f9dfd;
      border-radius: 0.19rem;
    }
    .el-main .c-join-us .c-ju-wrap .c-juw-wrap>div:nth-child(2){
      border-radius: 0.19rem;
      border: solid 0.06rem #ffffff;
      margin-left: 2rem;
    }
    /* 弹窗 */

    .show-qrcode>>>.el-dialog{
        border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px;
        height: auto;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        height: 5.63rem;
        font-size: 0.94rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */

    /* 双宝网络和合作伙伴信息 */
    .c-zz-she>span{
        font-size: 1.25rem;
        color: #404040;
    }
    .c-zz-she3{
        font-size: 0.94rem;
        margin-left: 3rem;
    }
    .c-zz-she3>p:nth-child(2){
        margin: .5rem 0;
    }
    .c-zz-she3>p:nth-child(4){
        margin-top:.5rem;
    }
    .c-zz-she3>p:nth-child(1){
        font-size: 1.25rem;
    }
    .c-zz-she2{
        margin-left: 1rem;
        width: 53%;
        font-size: 0.94rem;
    }
    .c-zz-she2>p:nth-child(3)>span{
        display: inline-block;
        width: 9rem;
        height: 1.44rem;
        background-image: linear-gradient(
                #ff7e3f,
                #ff7e3f),
        linear-gradient(
                #dcdcdc,
                #dcdcdc);
        background-blend-mode: normal,
        normal;
        border-radius: 0.13rem;
        font-size: 0.94rem;
        color: #ffffff;
        text-align: center;
        margin-right: .5rem;
        line-height: 1.44rem;
    }
    .c-zz-she2>p:nth-child(2){
        margin: 1rem 0;
    }
    .c-zhai-zhi-she-msg{
        width: 58.33%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        padding: 3rem 0;
        border-bottom: 0.0625rem solid #dcdcdc;

    }
    .c-zz-she{
        width: 15rem;
        height: 4.5rem;
        display: flex;
        padding-left: .5rem;
        align-items: center;
    }
    .c-zz-she>img{
      border-radius: 50%;
      box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
      -webkit-box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
      -moz-box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
    }


    /* 双宝网络和合作伙伴信息 */
    /* 搜索 */
    .c-agent-search{
        width: 40rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        margin-bottom: 7rem;
    }
    .c-agent-search>div{
        margin-left: .75rem;
        width: 8.44rem;
        height: 3.13rem;
        background-color: #3f9dfd;
        border-radius: 0.19rem;
        text-align: center;
        line-height: 3.13rem;
        color: #ffffff;

    }
    .c-agent-search>input{
        width: 27.31rem;
        height: 3.13rem;
        background-color: #ffffff;
        border: solid 0.06rem #dcdcdc;
        text-indent: 1rem;
    }



    .c-partner-msg2{
        width: 50rem;
        height: 1.88rem;
        color: #404040;
        margin: 4rem auto;
        font-size: 1.88rem;
    }
    .c-connect-jl{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .c-cjl{
        width: 9.75rem;
        height: 3.31rem;
        line-height: 3.31rem;
        text-align: center;
        background-color: #3f9dfd;
        border-radius: 0.19rem;
        color: #ffffff;
        cursor: pointer;
    }
    .c-connect-jl{
        width: 100%;
        height: 25rem;
        background: url('../../../assets/images/key.jpg')
    }
    .c-sq2{
        width: 8.44rem;
        height: 3.06rem;
        background-color: #3f9dfd;
        border-radius: 0.19rem;
        margin: 3rem auto 5rem auto;
        line-height: 3.06rem;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
    }
    /* 合作伙伴申请流程 */
    .c-process-wrap2{
        width: 65rem;
        margin: 0 auto;
        display: flex;
    }
    .c-process-wrap2>span{
        font-size: 0.94rem;
        color: #3f83ff;
        margin-top: .5rem;
        /*margin-right: 6.3rem;*/
        /*width: 6rem;*/
    }
    .c-process-wrap2>span:nth-of-type(1){
        margin-left: 5.5rem;
    }
    .c-process-wrap2>span:nth-of-type(2){
        margin-left: 8.5rem;
    }
    .c-process-wrap2>span:nth-of-type(3){
        margin-left: 8.8rem;
    }
    .c-process-wrap2>span:nth-of-type(4){
        margin-left: 8.7rem;
    }
    .c-process-wrap2>span:nth-of-type(5){
        margin-left: 8rem;
    }
    .c-process-wrap{
        width: 65rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
    }
    .c-pw-line{
        width: 10rem;
        height: 0.13rem;
        background-color: #dcdcdc;
    }
    .c-partner-msg{
        width: 17.63rem;
        height: 1.88rem;
        color: #404040;
        margin: 4rem auto;
        font-size: 1.88rem;
    }
    /* 合作伙伴条件 */
    .c-location-zl{
        width: 18.5rem;
        height: 14rem;
        display: flex;
        flex-direction: row;
        margin-right: 2rem;
    }
    .c-location-zl .c-outer-wrap{
        position: absolute;
        width: 17.31rem;
        height: 12.94rem;
    }
    .c-location-zl .c-outer-wrap .c-ow-div{
        width: 17.31rem;
        height: 12.94rem;
        background-color: #3f9dfd;
        opacity: 0.25;
        position: absolute;
        right: -1rem;
        top: -1rem;

    }
    .c-partner-condition{
        width: 80rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    .c-location-zl .c-outer-wrap .c-pc-wrap>p:nth-child(1){
        color: #3f9dfd;
        padding-left: 2rem;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
    }
    .c-location-zl .c-outer-wrap .c-pc-wrap>p:nth-child(2){
        font-size: 0.88rem;
        color: #404040;
        width: 13.44rem;
        padding-left: 2rem;

    }
    .c-location-zl .c-outer-wrap .c-pc-wrap{
        width: 17.31rem;
        height: 12.94rem;
        background-color: #ffffff;
        box-shadow: 0rem 0rem 0.56rem 0rem
        rgba(10, 27, 81, 0.13);
        position: absolute;
        z-index: 5;
    }

    /* 酷366合作伙伴 */
    .c-ybsp-img>div{
        margin-right: 7rem;
    }
    .c-ybsp-img>div>p{
        width: 9.6rem;
        text-align: center;
        font-size: 1rem;
        line-height: 2.75rem;
        letter-spacing: 0.05rem;
        color: #404040;
    }
    .c-ybsp-img{
        width: 50%;
        margin: 5rem auto 0 auto;
        display: flex;
        flex-direction: row;
    }
    .c-ybs-partner{
        width: 100%;
        height: 24.06rem;
        background-color: #f1f7fc;
    }
    .c-ybs-partner>p{
        text-align: center;
        padding-top: 4.7rem;
        font-size: 1.88rem;
        line-height: 2.75rem;
        letter-spacing: 0.09rem;
        color: #404040;
    }


    /* 酷366合作伙伴 */
    .c-yx-partner{
      width: 12.69rem;
      height: 1.88rem;
      font-size: 1.88rem;
      line-height: 2.75rem;
      letter-spacing: 0.09rem;
      color: #404040;
      margin: 5rem auto 0 auto;
    }
    .c-partner-show{
        width: 60%;
        margin: 2rem auto 5rem auto;
        display: flex;
        justify-content: space-between;
    }
    .c-partner-show-box{
      width: 0;
      flex: 0 0 24%;
    }
    .c-partner-show-box>div{
      padding-top: 1.25rem;
      margin-bottom: 0.625rem;
      display: block;
      text-align: center;
      width: 100%;
      height: 12rem;
      box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
      -webkit-box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
      -moz-box-shadow: 0 0 4px 2px rgba(0,0,0,0.43);
    }
    .c-partner-show-box>div>img{
      border-radius:25%;
    }
    .c-partner-show-box>span{
      display: block;
      text-align: center;
    }

    /* 加入我们 */


    /* /加入我们 */

</style>
