<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// console.log(document.documentElement.clientWidth);
// document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/* 初始化 */
* {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑", 'Avenir', Helvetica, Arial, sans-serif;
}
body,
button,
input,
select,
textarea {
  font: 12px/1.5tahoma, arial, \5b8b\4f53;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
address,
cite,
dfn,
em,
var {
  font-style: normal;
}
code,
kbd,
pre,
samp {
  font-family: couriernew, courier, monospace;
}
small {
  font-size: 12px;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
legend {
  color: #000;
}
fieldset,
img {
  border: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:hover{
  text-decoration: none;
}

/*html {*/
/*font-size: 16px;*/
/*}*/
/*!* 移动端适配 *!*/
/*@media screen and (max-width:300px) {*/
/*html,body {*/
/*font-size:2.5px;*/
/*}*/
/*}!* 大于301 小于350 *!	*/
/*@media screen and (min-width:301px) and (max-width:350px) {*/
/*html,body {*/
/*font-size:2.91px;*/
/*}*/
/*}!* 大于351 小于420 *!	*/
/*@media screen and (min-width:351px) and (max-width:420px) {*/
/*html,body {*/
/*font-size:3.5px;*/
/*}*/
/*}!* 大于421 小于490 *!	*/
/*@media screen and (min-width:421px) and (max-width:490px) {*/
/*html,body {*/
/*font-size:4px;*/
/*}*/
/*}!* 大于491 小于620 *!	*/
/*@media screen and (min-width:491px) and (max-width:620px) {*/
/*html,body {*/
/*font-size:5.33px;*/
/*}*/
/*}!* 大于621 小于720 *!	*/
/*@media screen and (min-width:621px) and (max-width:720px) {*/
/*html,body {*/
/*font-size:5.99px;*/
/*}*/
/*}!* 大于721 小于820 *!	*/
/*@media screen and (min-width:721px) and (max-width:820px) {*/
/*html,body {*/
/*font-size:6.83px;*/
/*}*/
/*}!* 大于821 小于920 *!	*/
/*@media screen and (min-width:821px) and (max-width:920px) {*/
/*html,body {*/
/*font-size:7.65px;*/
/*}*/
/*}!* 大于921 小于1020 *!	*/
/*@media screen and (min-width:921px) and (max-width:1020px) {*/
/*html,body {*/
/*font-size:8.51px;*/
/*}*/
/*}!* 大于1021 小于1120 *!	*/
/*@media screen and (min-width:1021px) and (max-width:1120px) {*/
/*html,body {*/
/*font-size:9.35px;*/
/*}*/
/*}!* 大于1121 小于1220 *!	*/
/*@media screen and (min-width:1121px) and (max-width:1220px) {*/
/*html,body {*/
/*font-size:10.1px;*/
/*}*/
/*}!* 大于1221 小于1320 *!	*/
/*@media screen and (min-width:1221px) and (max-width:1320px) {*/
/*html,body {*/
/*font-size:11.03px;*/
/*}*/
/*}!* 大于1321 小于1420 *!	*/
/*@media screen and (min-width:1321px) and (max-width:1420px) {*/
/*html,body {*/
/*font-size:11.85px;*/
/*}*/
/*}!* 大于1421 小于1520 *!	*/
/*@media screen and (min-width:1421px) and (max-width:1520px) {*/
/*html,body {*/
/*font-size:12.7px;*/
/*}*/
/*}!* 大于1521 1620 *!	*/
/*@media screen and (min-width:1521px) and (max-width:1620px) {*/
/*html,body {*/
/*font-size:13.56px;*/
/*}*/
/*}*/

/*@media screen and (min-width:1621px) and (max-width:1720px) {*/
/*html,body {*/
/*font-size:14.41px;*/
/*}*/
/*}*/
/*@media screen and (min-width:1721px) and (max-width:1820px) {*/
/*html,body {*/
/*font-size:15.23px;*/
/*}*/
/*}*/
/*@media screen and (min-width:1821px) and (max-width:1920px) {*/
/*html,body {*/
/*font-size:16px;*/
/*}*/
/*}*/
/*@media screen and (min-width:1921px) and (max-width:2020px) {*/
/*html,body {*/
/*font-size:16.84px;*/
/*}*/
/*}*/
/*@media screen and (min-width:2021px) and (max-width:2120px) {*/
/*html,body {*/
/*font-size:17.7px;*/
/*}*/
/*}*/
/*@media screen and (min-width:2121px) and (max-width:2220px) {*/
/*html,body {*/
/*font-size:18.60px;*/
/*}*/
/*}*/
/*@media screen and (min-width:2221px) and (max-width:2320px) {*/
/*html,body {*/
/*font-size:19.50;*/
/*}*/
/*}*/
/*@media screen and (min-width:2321px) and (max-width:2420px) {*/
/*html,body {*/
/*font-size:20.25px;*/
/*}*/
/*}*/

/* 初始化 */
</style>
