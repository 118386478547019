<template>
    <div style="width: 100%;height: auto">
      <div class="s_box s_h">
        <img class="header-logo" v-lazy="require('../../../assets/images/k366/logo.png')" alt="泉州市双宝网络技术有限公司-酷366"/>
        <div class="c_us">
          <div>咨询热线</div>
          <div><i class="el-icon-phone-outline" style="font-size: 30px;display: inline-block;margin-right: 1rem;"></i>189-5973-1860</div>
        </div>
      </div>
      <div ref="s_m" class="s_m">
        <nav class="s_box el-menu">
          <a v-for="(item, i) in navData" href="javascript:void(0);" @click="toSwitch(item['path'])" :class="{'el-menu-item': true, 'is-active': item['path']===activeIndex}">{{item['name']}}</a>
        </nav>
<!--        <el-menu ref="el-menu" class="s_box" mode="horizontal" :default-active="activeIndex" @select="toSwitch">-->
<!--          <el-menu-item v-for="(item, i) in navData" :index="item['path']">{{item['name']}}</el-menu-item>-->
<!--        </el-menu>-->
      </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data(){
          return{
            activeIndex: "",
            navData:[
              {
                path: '',
                name: '首页',
              },
              {
                path: 'software-purchase',
                name: '软件购买',
              },
              {
                path: 'agent-joining',
                name: '代理加盟',
              },
              {
                path: 'service-support',
                name: '服务与支持',
              },
            ],
          }
        },
        methods:{
            toSwitch(path) {
              const route = this.navData.find((item) => item['path'] === path);
              this.activeIndex = path;
              this.$router.push({path: `/${route['path']}`});
            },
        },
        created(){
          const route = this.navData.find((item) => item['path'] === this.$route['name']);
          if (route){
            this.activeIndex = route['path'];
          }else{
            console.log('路由名：', this.$route['name']);
          }
        },
        mounted(){
          const smDom = this.$refs['s_m'];
          const elMenuOffsetTop = smDom['offsetTop'];
          const smCall = () => {
            const s = document.documentElement.scrollTop || document.body.scrollTop;
            smDom['style']['position'] = s >= elMenuOffsetTop ? 'fixed' : 'static';
          };
          smCall.call(this);
          window.addEventListener('scroll', smCall);
        }
    }
</script>

<style scoped>
    .s_box{
      padding: 0 10%;
    }
    .s_h{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 128px;
    }
    .s_h .c_us{
      text-align: right;
      color: #999999;
    }
    .s_h .c_us>div:last-child{
      margin-top: 0.5rem;
      color: #B7A99D;
      font-size: 24px;
    }
    .header-substitute{
        height: 3.75rem;
    }
    .header{
        width:100%;
        height: 4.6875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../../assets/images/k366/header-nav-bg.jpg") repeat-x;
        position: fixed;
        z-index:999;
        box-shadow: rgba(149, 149, 149, 0.35) 0 0 10px;
    }
    .sub-header{
        padding: 0 10%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .s_m{
      width: 100%;
      position: static;
      top: 0;
      left: 0;
      z-index: 9999;
      overflow: hidden;
    }
    .el-menu{
      background-color: #2B2B2B;
      white-space:nowrap;
    }
    .el-menu>a:hover{
      background-color: #076CE0;
    }
    .el-menu-item {
      color: #FFFFFF;
      margin-right: 32px;
      min-width: 158px;
      text-align: center;
      font-size: 24px;
      display: inline-block;
    }
    .el-menu-item.is-active{
      background-color: #076CE0;
      color: #FFFFFF;
      border-bottom: rgb(255, 208, 75) 1px solid;
    }
</style>