<template>
    <div>
        <Header></Header>
        <div class="nav">
            <div class="nav-t1 nav-t1-active">基础版</div>
            <div class="nav-t1">直营连锁版</div>
            <div class="nav-t1">私有部署版</div>
        </div>
        <div class="nav-prt"></div>
        <div class="main1">
            <div class="m1-t1">免费试用，选择适合您的版本</div>
            <div class="m1-t2">酷366功能强大，并且易于上手，</div>
            <div class="m1-t2">适用于任何规模的企业或门店。</div>
            <div class="m1-t2">从现在开始，酷366基础版免费试用15天。</div>
            <div class="m1-wrap" v-for="item in packages">
                <div class="m1-wp-t1">{{item.name}}</div>
                <div class="m1-wp-t2">{{item.explanation}}</div>
                <div class="m1-wp-t3">
                    <span class="m1-wpt3-sp1">¥</span>
                    <span class="m1-wpt3-sp2">{{item.money}}</span>
                    <span class="m1-wpt3-sp3">/年</span>
                </div>
                <div class="m1-wp-t4">免费试用</div>
                <div class="m1-wp-t5" v-for="item1 in item.content">{{item1}}</div>
                <div class="m1-wp-t5">2个APP账号</div>
                <div class="m1-wp-t6"></div>
                <div class="m1-wp-t7">包含功能</div>
                <div class="m1-wp-t5" v-for="item2 in item.features">{{item2}}</div >
                <div class="m1-wp-t7">服务</div >
                <div class="m1-wp-t5" v-for="item3 in item.service">{{item3}}</div >
            </div>
            <div class="m1-wrap" style="border-top: .188rem solid #4a21f8;">
                <div class="m1-wp-t1">私有部署版</div>
                <div class="m1-wp-t2">适用本地独立化部署</div>
                <div class="m1-wp-t3">
                    <img class="m1-wpt3-img" v-lazy="require('../../../assets/images/tc00.png')">
                </div>
                <div class="m1-wp-t4">咨询顾问</div>
                <div class="m1-wp-t5" >无限制站点</div>
                <div class="m1-wp-t5" >无限制APP账号数量</div>
                <div class="m1-wp-t6"></div>
                <div class="m1-wp-t7">包含功能</div>
                <div class="m1-wp-t5">包含连锁版所有功能</div >
                <div class="m1-wp-t5">本地私有部署</div >
                <div class="m1-wp-t5">企业域名定制</div >
                <div class="m1-wp-t5">应用LOGO自定义</div >
                <div class="m1-wp-t5">定制功能开发（费用另计）</div >
                <div class="m1-wp-t5">小程序模板定制（费用另计）</div >
                <div class="m1-wp-t7">服务</div >
                <div class="m1-wp-t5">电话盒子一台（赠送）</div >
                <div class="m1-wp-t5">7×12小时在线支持</div >
            </div>
        </div>
        <Horselight :cTitle="'深受桶装水行业各大企业信任'"></Horselight>
        <div class="main2"></div>
        <Bottom></Bottom>
        <CustomerService></CustomerService>
    </div>
</template>

<script>
    import Bottom from '@/components/Mobile/Bottom';
    import Header from '@/components/Mobile/Header';
    import CustomerService from '@/components/Mobile/CustomerService';
    import Horselight from '@/components/Mobile/Horselight';
    export default {
        name: "SoftwarePurchase",
        components:{
            Header,
            Bottom,
            Horselight,
            CustomerService
        },
        data(){
          return{
              packages:[
                  {
                      name: '基础版',
                      explanation: '适用中小型水站',
                      money: '2400',
                      content: [
                          '1间站点',
                          '2个APP账号',
                      ],
                      features: [
                          '订单管理系统',
                          '客户管理系统',
                          '商品管理系统',
                          '站点管理系统',
                          '员工管理系统',
                          '库存管理系统',
                          '财务统计系统',
                          '微信小程序商城',
                          '微信公众号管理',
                          '营销工具',
                          '来电弹屏',
                          '数据导出',
                      ],
                      service: [
                          '电话盒子一台（赠送）',
                          '7×12小时在线支持'
                      ]
                  },
                   {
                      name: '连锁直营版',
                      explanation: '适用连锁水站',
                      money: '5899',
                      content: [
                          '无限制站点',
                          '20个APP账号 ',
                      ],
                      features: [
                          '包含基础版所有功能',
                          '账号价格8折',
                          '多套小程序模板选择',
                          '付费功能优先体验',
                      ],
                      service: [
                          '电话盒子一台（赠送）',
                          '7×12小时在线支持'
                      ]
                  },

              ],
          }
        },
    }
</script>

<style scoped>
    .nav-prt{
        background: #fff;
        z-index: 40;
        position: fixed;
        height: 2.125rem;
        width: 100%
    }
    .nav{
        width: calc(100% - 1.5rem);
        height: 2.125rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        z-index: 50;
        top: 0;
        left: 1.5rem;
    }
    .nav-t1{
        font-size: 0.438rem;
        color: #404040;
        padding-bottom: .1rem;
    }
    .nav-t1-active{
        color: #267dff;
        border-bottom: 3px solid #148ff6;;
    }
    .main1{
        /*margin-top: 2.125rem;*/
        background: #fff url('../../../assets/images/home/04.png') repeat-y 0 2.125rem;
        background-size: 100%;
        min-height:  10rem;
        overflow: hidden;
    }
    .m1-t1{
        margin-top: 2.125rem;
        font-size: 0.688rem;
        color: #404040;
        text-align: center;
    }
    .m1-t2{
        font-size: 0.438rem;
        color: #404040;
        text-align: center;
    }
    .m1-t2:nth-of-type(2){
        margin-top: 0.813rem;
    }
    .m1-wrap{
        padding: 0.703rem 0 1.469rem;
        margin: 1.094rem  0.625rem 0.35rem;
        border-top: 0.188rem solid #72c4fe;
        background: #fff;
        box-shadow: 0 0 0.891rem 0.047rem rgba(195, 195, 201, 0.7);
    }
    .m1-wrap:nth-of-type(3){
        border-top: 0.188rem solid #2190f8;
    }
    .m1-wrap>div{
        text-align: center;
    }
    .m1-wp-t1{
        font-size: 0.625rem;
        color: #267dff;
    }
    .m1-wp-t2{
        font-size: 0.438rem;
        color: #404040;
        margin-top: 0.563rem;
    }

    .m1-wp-t3{
        margin-top: 0.984rem;
    }
    .m1-wpt3-img{
        width: 1.734rem;
        height: 1.563rem;
    }
    .m1-wpt3-sp1{
        font-size: 0.469rem;
        color: #267dff;
        position: relative;
        bottom: .49rem;
        z-index: 5;
    }
    .m1-wpt3-sp2{
        font-size: 1.25rem;
        color: #404040
    }
    .m1-wpt3-sp3{
        font-size: 0.688rem;
        color: #267dff;
    }
    .m1-wp-t4{
        width: 45%;
        height: 1.266rem;
        line-height: 1.266rem;
        background-image: linear-gradient(-45deg, #5e7cff 0%, #6f96fe 50%, #80affc 100%), linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        box-shadow: 0rem 0rem 0.078rem 0rem rgba(132, 140, 176, 0.63);
        border-radius: 0.047rem;
        font-size: 0.531rem;
        color: #ffffff;
        margin: 0.984rem auto 0;

    }
    .m1-wp-t5{
        font-size: 0.469rem;
        color: #404040;
        margin-top: 0.422rem;
    }
    .m1-wp-t6{
        margin: 0.594rem 0.641rem 0;
        border-top: 1px solid #e5e5e5;
    }
    .m1-wp-t7{
        font-size: 0.5rem;
        color: #404040;
        margin-top: 0.574rem;
        font-weight: bold;
    }
    .main2{
        width: 100%;
        padding: 1.422rem 0;
        background-color: #f1f7fc;
    }

</style>
