<template>
    <div style="width: 100%">
        <div class="c-connect-we">
            <div>联系我们</div>
            <div class="c-cw-inner">
                <div class="c-cwi-wrap">
                  <div>
                    <img v-lazy="require('../../../assets/images/computed.png')" alt="" />
                    <p>泉州市双宝网络技术有限公司</p>
                  </div>
                  <div>
                    <img v-lazy="require('../../../assets/images/phone.png')" alt="" />
                    <p>18959731860</p>
                  </div>
                </div>
                <div class="c-cwi-wrap">
                  <div>
                    <img v-lazy="require('../../../assets/images/location.png')" alt="" />
                    <p>福建省泉州市茶博会隆恩公馆11号楼111号</p>
                  </div>
                    <div>
                      <img v-lazy="require('../../../assets/images/qq.png')" alt="" />
                      <p><a href="https://qm.qq.com/cgi-bin/qm/qr?k=BmpoMvbngr_8ncSTWNC9YcJptBxOF0zZ&noverify=0" target="_blank">437795529</a></p>
                    </div>
                </div>
                <!-- 在线咨询 -->
                <!--<div class="c-online-zx" style="margin-bottom:1rem;margin-top:3.5rem;">在线咨询</div>-->
                <!--<div class="c-online-zx">详细地图</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>

<style scoped>
    /* 联系我们 */
    .c-connect-we{
        width: 100%;
        height: auto;
        background-color: #fff;
    }
    .c-connect-we>div:nth-child(1){
        width: 100%;
        height: 1.81rem;
        padding-top: 4rem;
        font-size: 1.88rem;
        color: #303030;
        text-align: center;
    }
    .c-connect-we .c-cw-inner{
        width: 51rem;
        height: auto;
        margin: 4rem auto 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .c-connect-we .c-cw-inner .c-cwi-wrap{
      flex: 0 0 50%;
      margin-bottom: 2rem;
      font-size: 0.94rem;
    }
    .c-connect-we .c-cw-inner .c-cwi-wrap>div{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .c-connect-we .c-cw-inner .c-cwi-wrap>div>img{
      width: 2.75rem;
      height: 2.75rem;
    }
    .c-connect-we .c-cw-inner .c-cwi-wrap>div>p{
        flex: 1 1 auto;
        height: 2.75rem;
        line-height: 2.75rem;
        margin-left: .88rem;
    }

    .c-online-zx{
        width: 47.38rem;
        height: 4.13rem;
        line-height: 4.13rem;
        text-align: center;
        border-radius: 0.94rem;
        border: solid 0.13rem #aaaaaa;
        font-size: 1.25rem;
        color: #303030;
        cursor: pointer;
    }
</style>
