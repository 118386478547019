<template>
  <el-container>
    <el-header height="auto">
      <Header></Header>
    </el-header>
    <el-main>
      <!--内容1-->
      <div class="block-box lead">
        <img class="logo" v-lazy="require('../../../assets/images/k366/logo.png')" alt="双宝.新零售酷366版" />
<!--        <span class="box-text-08 title">双宝.新零售酷366版</span>-->
<!--        <span class="box-text-05 describe">三端合一数据实时同步<br/>全新升级智能下单派单</span>-->
        <el-image :src="require('../../../assets/images/mobile/1-0.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
        <div class="content">
          <el-image :src="require('../../../assets/images/mobile/1-1.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
          <div class="download-button" @click="DownloadAndCustomerServiceIsShowFunc">下载酷366版</div>
        </div>
      </div>
      <!--多种设备完美支持-->
      <div class="block-box bw bw-pic-1" data-wow-delay="100ms" data-wow-duration="2s">
        <div class="box-text-05">
          <div class="bw-h">酷366•能给您带来什么？</div>
          <div class="bw-a">『唯有享受便捷，轻松提高效益不再难事』</div>
        </div>
      </div>
      <div class="block-box theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">多种设备完美支持</div>
            <div class="wow animate__bounceInUp theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「与时俱进，手机在手，天下我有」</div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/09.png')" />
                <span>电脑版</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/10.png')" height="50" width="50"/>
                <span>手机版</span>
              </div>
            </div>
            <div class="wow animate__backInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">电脑管理端@移动手机端</div>
            <div class="wow animate__backInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">电脑管理端、手机APP配送端、微信客户端，三端互通</div>
            <div class="wow animate__backInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">管理端、配送端，接待客户，自动弹屏，智能匹配</div>
            <div class="wow animate__backInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">日常业务可随时随地的使用手机进行管理</div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <el-image :src="require('../../../assets/images/function-download/08.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
          </div>
        </div>
      </div>

      <!--微信小程序商城-->
      <div class="block-box bw bw-pic-2" data-wow-delay="100ms" data-wow-duration="2s">
        <div class="box-text-05">
          <div class="bw-h">酷366，客户随时随地自助下单</div>
          <div class="bw-a">『减免与客户一对一接待，解决效率低问题』</div>
        </div>
      </div>
      <div class="block-box theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">微信小程序商城</div>
            <div class="wow animate__lightSpeedInLeft theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「免安装，秒响应，更亲民，多元化」</div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/13.png')"/>
                <span>手机支付</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/14.png')"/>
                <span>移动商城</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/15.png')"/>
                <span>营销工具</span>
              </div>
            </div>
            <div class="wow animate__zoomIn theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">微信商城@自助下单</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">减免与客户一对一接待，解决效率低问题</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">多店铺切换，加盟店、直营店</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">财务独立，综合业务隔离，定位展示，推广绑定</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">店面模板实时切换，营销活动节日庆典永不耽误</div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right" style="text-align: center">
            <el-image :src="require('../../../assets/images/mobile/06.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
          </div>
        </div>
      </div>

      <!--配送APP手机管理端-->
      <div class="block-box bw bw-pic-3" data-wow-delay="100ms" data-wow-duration="2s">
        <div class="box-text-05">
          <div class="bw-h">收支不成正比怎么办？</div>
          <div class="bw-a">『提高效率是王道、减少支出是根本』</div>
        </div>
      </div>
      <div class="block-box theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">配送APP手机管理端</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「制度考勤、分派任务、绩效统计、路线规划」</div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/19.png')"/>
                <span>订单管理</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/20.png')"/>
                <span>配送管理</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/21.png')"/>
                <span>消息通知</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/23.png')"/>
                <span>财务管理</span>
              </div>
            </div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">移动管理后台@日常业务处理</div>
            <div class="wow animate__slideInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">任务：配送任务明确，免沟通流水化处理，节省时间</div>
            <div class="wow animate__slideInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">目标：实时绩效统计，促进工作积极性提高整体效率</div>
            <div class="wow animate__slideInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">发展：前期业务转型，增产不加人减少人工支出成本</div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right" style="text-align: center">
            <el-image :src="require('../../../assets/images/function-download/17.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
          </div>
        </div>
      </div>
      <!--给您最好用的订单系统-->
      <div class="block-box bw bw-pic-4" data-wow-delay="100ms" data-wow-duration="2s">
        <div class="box-text-05">
          <div class="bw-h">一个转折一段路程一次选择</div>
          <div class="bw-a">『与君同行伴君左右』</div>
        </div>
      </div>
      <div class="block-box theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">给您最好用的订单系统</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「主控制端、界面鲜明、分权管理、自动升级」</div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/26.png')"/>
                <span>手机支付</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/27.png')"/>
                <span>移动商城</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/28.png')"/>
                <span>营销工具</span>
              </div>
            </div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">超级管理端@傻瓜式操作</div>
            <div class="wow animate__slideInRight theme-content-p">客户、订单、财务、进销存等，一目了然</div>
            <div class="wow animate__slideInRight theme-content-p">按照业务实际需求选择配置方案，提高人机优越体验</div>
            <div class="wow animate__slideInRight theme-content-p">订单自动分配，无异常订单无需人工分配</div>
            <div class="wow animate__slideInRight theme-content-p">好系统听众评，行业性通用功能永不收费</div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <el-image :src="require('../../../assets/images/k366/pc-manage.png')" style="height: 100%;" fit="contain" lazy></el-image>
          </div>
        </div>
      </div>
      <!--营销推广工具-->
      <div class="block-box bw bw-pic-5" data-wow-delay="100ms" data-wow-duration="2s">
        <div class="box-text-05">
          <div class="bw-h">酷366，是快捷</div>
          <div class="bw-a">省心的操作体验</div>
        </div>
      </div>
      <div class="block-box theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">营销推广工具</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「快速获客，提升单量，营销利器，必不可少」</div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/30.png')"/>
                <span>获取新客</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/31.png')"/>
                <span>刺激消费</span>
              </div>
              <div class="theme-content-i-h"></div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/32.png')"/>
                <span>维系老客</span>
              </div>
            </div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">开拓市场@营销战略</div>
            <div class="wow animate__slideInRight theme-content-p">多元流量、快速获客、精准定位、提高转化</div>
            <div class="wow animate__slideInRight theme-content-p">灵活定制、刺激消费、提升单量、品牌曝光</div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <el-image :src="require('../../../assets/images/function-download/33.png')" data-wow-delay="100ms" data-wow-duration="2s" style="height: 100%;" fit="contain" lazy></el-image>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer height="auto">
      <Bottom ref="bottom"></Bottom>
    </el-footer>
  </el-container>
</template>

<script>
    import Bottom from '@/components/Mobile/Bottom';
    import Header from '@/components/Mobile/Header';
    import CustomerService from '@/components/Mobile/CustomerService';
    import DownloadAndCustomerService from '@/components/Mobile/DownloadAndCustomerService';
    export default {
        name: "FunctionDownload",
        components:{ Bottom, Header, CustomerService },
        data(){
            return{
                screenHeight: document.documentElement.clientHeight, // 屏幕尺寸
                isBackgroundImage:1, // 背景图可见
                DownloadAndCustomerServiceIsShow: false,
            }
        },
        methods:{
            /**
             * 显示下载和客服
             */
            DownloadAndCustomerServiceIsShowFunc(){
                this.$refs['bottom'].DownloadAndCustomerServiceIsShow = !this.$refs['bottom'].DownloadAndCustomerServiceIsShow;
            },
            // handleScroll () { //改变元素#searchBar的top值
            //     var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            //     scrollTop = scrollTop/document.documentElement.clientHeight;
            //     if (scrollTop >= 0 && scrollTop < 2) {
            //         this.isBackgroundImage = 1;
            //     }else if(scrollTop >= 2 && scrollTop < 4) {
            //         this.isBackgroundImage = 2;
            //     }else if(scrollTop >= 4 && scrollTop < 6) {
            //         this.isBackgroundImage = 3;
            //     }else if(scrollTop >= 6 && scrollTop < 8) {
            //         this.isBackgroundImage = 4;
            //     }else if(scrollTop >= 6 && scrollTop < 10) {
            //         this.isBackgroundImage = 5;
            //     }else {
            //         this.isBackgroundImage = 1;
            //     }
            // },
        },

        mounted () {//给window添加一个滚动滚动监听事件
            // window.addEventListener('scroll', this.handleScroll)
        },
        destroyed () {//离开该页面需要移除这个监听的事件
            // window.removeEventListener('scroll', this.handleScroll)
        },

    }
</script>

<style scoped>
    .el-header,.el-main,.el-footer{
      padding: 0;
    }
    .el-main {
      overflow: hidden;
    }
    .el-main .block-box{
      width: 100vw;
      height: 100vh;
      background-image: none;
      background-size: cover;
      background-position: center center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    .el-main .block-box .box-text-08{
      font-size: 0.8rem;
    }
    .el-main .block-box .box-text-05{
      font-size: 0.5rem;
    }
    /*内容1*/
    .el-main .block-box.lead{
      background-image: url("../../../assets/images/mobile/1-1-1.jpg");
    }
    .el-main .lead .logo{
        width: 80%;
        height: auto;
        margin-top: 0.922rem;
    }
    .el-main .lead .title{
        color: #ffffff;
        text-align: center;
    }
    .el-main .lead .describe{
      color: #ffffff;
      letter-spacing: 0.022rem;
      text-align: center;
    }
    .el-main .lead .content{
      width: 100%;
      height: auto;
      text-align: center;
    }
    .el-main .lead .content .illustration{
      width: 100%;
    }
    .download-button{
        display: block;
        width: 6.406rem;
        height: 1.391rem;
        margin: 0 auto;
        line-height: 1.391rem;
        background-color: #ffffff;
        box-shadow: 0 0 0.28rem 0 rgba(47, 56, 233, 0.19);
        border-radius: 1.39rem;
        color: #428dfa;
        text-align: center;
        position:relative;
        bottom: 2rem;
        font-size: 0.5rem;
    }
    /*内容2*/
    .bw {
      background-attachment: fixed;
      justify-content: center;
      text-align: justify;
      overflow: hidden;
      color: #ffffff;
    }
    .bw .bw-h, .bw .bw-a{
      text-align: center;
      line-height: 1rem;
    }
    .bw.bw-pic-1 {
      background-image: url("../../../assets/images/function-download/07.jpg");
    }
    .bw.bw-pic-2 {
      background-image: url("../../../assets/images/function-download/16.jpg");
    }
    .bw.bw-pic-3 {
      background-image: url("../../../assets/images/function-download/11.jpg");
    }
    .bw.bw-pic-4 {
      background-image: url("../../../assets/images/function-download/24.jpg");
    }
    .bw.bw-pic-5 {
      background-image: url("../../../assets/images/function-download/29.jpg");
    }

    .theme {
      justify-content: space-between;
    }
    .theme .theme-box-left {
      width: 100%;
      height: 50%;
      overflow: hidden;
    }
    .theme .theme-box-left .theme-content-left {
      /*float: right;*/
      /*margin-right: 1rem;*/
      /*width: auto;*/
    }
    .theme .theme-box-right {
      width: 100%;
      height: 50%;
      overflow: hidden;
    }
    .theme .theme-box-right .theme-content-right {
      height: 100%;
      text-align: center;
      /*margin-left: 1rem;*/
      /*float: left;*/
    }
    .theme .theme-content-h {
      width: 100%;
      text-align: center;
      font-size: 0.75rem;
      letter-spacing: 0.075rem;
      color: #404040;
    }
    .theme .theme-content-a {
      font-size: 0.375rem;
      line-height: .7rem;
      letter-spacing: 0.038rem;
      color: #707070;
      margin-top: 0.516rem;
      text-align: center;
    }
    .theme .theme-content-p {
      font-size: 0.406rem;
      text-align: center;
      line-height: 0.697rem;
      font-weight: bold;
    }
    .theme .theme-content-p.active {
      margin-top: 1rem;
    }
    .theme .theme-content-i-h {
      width: 0;
      height: 1.25rem;
      border-left: 1px solid #dcdcdc;
    }
    .theme .theme-content-i {
      height: auto;
      margin-top: 0.484rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      letter-spacing: 0.059rem;
      color: #707070;
    }
    .theme .theme-content-i .theme-icon {
      text-align: center;
      margin: auto .3125rem;
      /*width: 5rem;*/
      height: auto;
    }
    .theme .theme-content-i .theme-icon>img {
      width: 1.266rem;
      height: 1.25rem;
    }
    .theme .theme-content-i .theme-icon>span {
      display: block;
      text-align: center;
    }

    .main2,.main4, .main6, .main8, .main10{
        /*background: url('../../../assets/images/mobile/main2.jpg') center 0 no-repeat;*/
        /*background-size: cover;*/
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.547rem;
        letter-spacing: 0.109rem;
        color: #ffffff;
        width: 100%;
        /*background-image:url('../../../assets/images/mobile/main2.jpg');*/
        /*background-repeat: no-repeat;*/
        /*background-position: center;*/
        /*background-attachment: fixed;*/

    }
    .fill-before:before{
        content:'';
    }
    .main2:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main2.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main4:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main3.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main6:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main4.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main8:before {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('../../../assets/images/mobile/main5.jpg') center 0 no-repeat;
        background-size: cover;
    }
    .main10:before {
         position: fixed;
         z-index: -1;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         background: url('../../../assets/images/mobile/main6.jpg') center 0 no-repeat;
         background-size: cover;
     }

    .m2-t1, .m4-t1, .m6-t1, .m8-t1, .m10-t1{
        text-align: center;
        line-height: 1rem;
    }
    .main3, .main5, .main7, .main9, .main11{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
    }
    .m3-t1,.m5-t1,.m7-t1,.m9-t1,.m11-t1{
        font-size: 0.75rem;
        letter-spacing: 0.075rem;
        color: #404040;
    }
    .m3-t2,.m5-t2,.m7-t2,.m9-t2,.m11-t2{
        font-size: 0.375rem;
        line-height: .7rem;
        letter-spacing: 0.038rem;
        color: #707070;
        margin-top: 0.516rem;
        text-align: center;
    }
    .m3-wrap,.m5-wrap,.m7-wrap,.m9-wrap,.m11-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.484rem;
    }
    .m3-wp-l, .m3-wp-r,.m5-wp-l, .m5-wp-r,.m7-wp-l, .m7-wp-r,.m9-wp-l, .m9-wp-r,.m11-wp-l, .m11-wp-r{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #707070;
        letter-spacing: 0.2rem;
    }
    .m3-wpl-img1, .m5-wpl-img1, .m7-wpl-img1, .m9-wpl-img1, .m11-wpl-img1{
        width: 1.266rem;
        height: 1.25rem;
    }
    .m3-wp-t1,.m5-wp-t1,.m7-wp-t1,.m9-wp-t1,.m11-wp-t1{
        height: 75%;
        border-left: 1px solid #dcdcdc;
        margin: 0 0.516rem 0;
    }
    .m3-wpr-img1,.m5-wpr-img1,.m7-wpr-img1,.m9-wpr-img1,.m11-wpr-img1{
        width: 1.266rem;
        height: 1.25rem;
    }
    .m3-wpr-t1, .m3-wpl-t1,.m5-wpr-t1, .m5-wpl-t1,.m7-wpr-t1, .m7-wpl-t1,.m9-wpr-t1, .m9-wpl-t1,.m11-wpr-t1, .m11-wpl-t1{
        font-size: 0.294rem;
        letter-spacing: 0.059rem;
        color: #707070;
    }
    .m3-t3, .m5-t3, .m7-t3, .m9-t3, .m11-t3{
        font-size: 0.406rem;
        text-align: center;
        line-height: 0.697rem;
        font-weight: bold;
        margin-top: 1rem;
    }
    .m3-img1, .m5-img1, .m7-img1, .m9-img1, .m11-img1{
        width: 92%;
        margin-top: 1.313rem;
    }
    /*.main4{*/
        /*background: url("../../../assets/images/mobile/main3.jpg") fixed;*/
        /*background-size: cover;*/
     /*}*/
    .main5,.main9,.main11{
        justify-content: space-between;
    }
    .m5-t1,.m9-t1,.m11-t1{
        margin-top: .844rem;
    }
    .m5-img1,.m9-img1,.m11-img1{
        width: 65%;
    }
    /*.main6{*/
        /*background: url("../../../assets/images/mobile/main4.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    /*.main8{*/
        /*background: url("../../../assets/images/mobile/main5.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    /*.main10{*/
        /*background: url("../../../assets/images/mobile/main6.jpg") fixed;*/
        /*background-size: cover;*/
    /*}*/
    .main12{
        width: 100%;
        height: auto;
        display: flex;
    }
</style>
