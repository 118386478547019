<template>
    <div style="width: 100%;height: auto">
        <div class="bottom">
            <div class="btm-l">
                <div class="btm-l-t1">联系我们</div>
<!--                <div class="btm-l-t2">‭0592-5793363‬</div>-->
                <div class="btm-l-t3">
                    <div class="customer-service">
                      <div v-for="(item, i) in customerQrdImg" class="c-s">
                        <span>{{item['occupation']}}：{{item['name']}}</span>
                        <span class="btm-l-t3-t1" @click='advisoryService(i)'>{{item['action']}}</span>
                      </div>
                    </div>
                </div>
                <div class="btm-l-t3">意见建议：kerter@qq.com</div>
                <div class="btm-l-t3">@2022 双宝网络·
                    <a class = "beianhao" rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank" style = "color: #ffffff">闽ICP备2021009614号-1</a>
                </div>
            </div>
            <div v-if="weChatPublicPlatform" class="btm-r">
                <div class="btm-r-t">
                    <img class="btm-r-t-img" v-lazy="require('../../../assets/images/function-download/35.png')" alt="" />
                    <span>扫一扫关注微信公众号</span>
                </div>
                <img class="btm-r-b" v-lazy="weChatPublicPlatform['img']" />
            </div>
        </div>
        <CustomerService ref="cs" :csc="customerServiceConfig"></CustomerService>
    </div>
</template>

<script>
    import CustomerService from '@/components/CustomerService';
    export default {
        name: "Bottom",
        components: {
          CustomerService,
        },
        props: {
          customerServiceConfig: {
            type: Object,
            default: function(){
              return {mainIsShow: true};
            }
          },
        },
        data(){
          return{
              weChatPublicPlatform: null,
              customerQrdImg: [],
          }
        },
        methods:{
            // 咨询客服
            advisoryService(i) {
                return this.$refs['cs'].advisoryService(i);
            },
            getCustomerService(){
              return this.$refs['cs'];
            },
        },
        mounted() {
          this.customerQrdImg = [...this.$refs['cs'].customerQrdImg];
          this.weChatPublicPlatform = {...this.$refs['cs'].weChatPublicPlatform};
        }
    }
</script>

<style scoped>
    .bottom{
        height: 27.06rem;
        padding: 0 10%;
        background: #303030;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
    }
    .btm-l{
        /*width: 50rem;*/
        /*margin-right: 23.44rem;*/
    }
    .btm-l-t1{
        font-size: 1.56rem;
        letter-spacing: 0.06rem;
    }
    .btm-l-t2{
        font-size: 1.56rem;
        letter-spacing: 0.06rem;
        margin-top: 1.94rem;
    }
    .btm-l-t3{
        font-size: 1.25rem;
        letter-spacing: 0.05rem;
        margin-top: 1.94rem;
    }
    .btm-l-t3 .customer-service{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 42.5rem;
    }
    .btm-l-t3 .customer-service .c-s:nth-child(n+3){
      padding-top: 1.94rem;
    }
    .btm-l-t3-t1{
        display: inline-block;
        margin-left: 3rem;
        width: 7.5rem;
        text-align: center;
        border-radius: 1rem;
        height: 2rem;
        line-height: 2rem;
        background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
        background-blend-mode: normal, normal;
        letter-spacing: 0.04rem;
        cursor: pointer;
    }
    .btm-r{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btm-r-t{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        letter-spacing: 0.03rem;
    }
    .btm-r-t-img{
        width: 1.5rem;
        height: 1.44rem;
        margin-right: 0.69rem;
    }
    .btm-r-b{
        margin-top: 1.81rem;
        width: 13.19rem;
        height: 13.19rem;
    }
    /* 弹窗 */

    .show-qrcode>>>.el-dialog{
        border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px;
        height: auto;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1.3rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        height: 5.63rem;
        font-size: 1.3rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */
    .beianhao:hover{
        text-decoration: underline;
    }
</style>
