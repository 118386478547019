<template>
    <!-- 弹窗 -->
    <div class="show-qrcode">
        <el-dialog :visible="dialogVisible" width="90%" @close="closeParentDialog()">
            <div class="qrcode-t">
                <div class="qrcode-t-w1">
                    <img v-lazy="require('../../../assets/images/k366/logo.png')" height="34" />
                    <img @click="closeParentDialog()" v-lazy="require('../../../assets/images/function-download/37.png')"/>
                </div>
                <div class="qrcode-t-w2">客户经理：{{customerServiceData.name?customerServiceData.name:'双宝网络'}}</div>
                <div class="qrcode-t-w3">
                    <img v-lazy="customerServiceData.img"/>
                </div>
                <div class="qrcode-t-w4">点击拨打：<a style="color:#404040" :href="'tel:'+customerServiceData.phone">{{customerServiceData.phone}}</a></div>
                <span class="qrcode-t-w5">添加微信号：{{customerServiceData.phone}}</span>
                <el-button @click="copyPhone" :data-clipboard-text="customerServiceData.phone" class="copy_phone">复制</el-button>
            </div>
        </el-dialog>
    </div>
    <!-- 弹窗 -->
</template>

<script>
    import Clipboard from 'clipboard';
    export default {
        name: "customer-service-qrd",
        props: {
            customerServiceData: {
                type: Object,
                default() {
                    return {
                        name: '小易',
                        img: require('../../../assets/images/function-download/38.png'),
                        phone: '18959731860',
                    }
                },
            },
            dialogVisible:{
                type: Boolean,
                default: false
            },
        },
        data(){
            return{
                // dialogVisible: false,
            }
        },
        methods:{

            /**
             *  传入父级关闭弹窗
             */
            closeParentDialog(){
                this.$emit('closeParentDialog', false);
            },

            /**
             * 复制号码
             */
            copyPhone() {
                var clipboard = new Clipboard('.copy_phone');
                clipboard.on('success', e => {
                    this.$message({message: '复制成功', type: 'success'});
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    // 不支持复制
                    this.$message({message: '该浏览器不支持自动复制', type: 'warning'});
                    // 释放内存
                    clipboard.destroy();
                })
            }
        },
        created(){
            console.log(this.customerServiceData)
        }
    }
</script>

<style scoped>
    /* 弹窗 */
    .show-qrcode>>>.el-dialog{
        border-radius: .5rem;

    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 1rem .5rem 1rem .5rem;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w1>img:nth-of-type(1){
        width: 40%;
        height: auto;
    }
    .qrcode-t-w1>img:nth-of-type(2){
        height: .6rem;
        width: .6rem;
    }
    .qrcode-t-w2{
        width: 100%;
        height: 1rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: .5rem;
        line-height: 1rem;
        color: #404040;
        margin: .5rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
    }
    .qrcode-t-w3>img{
        width: 100%;
    }
    .qrcode-t-w4{
        color: #404040;
        margin-left: 1rem;
        font-size: .4rem;
    }
    .qrcode-t-w5{
        color: #404040;
        font-size: .4rem;
        margin-left: 1rem;
    }
    .copy_phone{
        margin-top: .1rem;
        border: 0;
        color: blue;
        background: transparent;
    }
    /* 弹窗 */
</style>
