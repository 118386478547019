<template>
  <el-container>
    <el-header height="auto">
      <Header></Header>
    </el-header>
    <el-main>
      <!-- 海报 -->
      <div class="poster">
        <div class="wow animate__fadeInDown poster-t1" data-wow-delay="100ms" data-wow-duration="2s">双宝新零售商城配送管理系统</div>
        <div class="wow animate__fadeInDown poster-t2" data-wow-delay="100ms" data-wow-duration="2s">专注同城新零售O2O、O2P多元酷366版全面上线</div>
<!--        <div class="wow animate__fadeInDown poster-t2" data-wow-delay="500ms" data-wow-duration="2s">电脑软件管理端、手机APP订单配送端、微信公众号小程序客户端</div>-->
<!--        <div class="wow animate__fadeInDown poster-t2" data-wow-delay="1s" data-wow-duration="2s">三端合一数据实时同步，全新升级酷366版智能下单派单</div>-->
        <div class="poster-wrap">
          <a class="wow animate__zoomIn" data-wow-delay="100ms" data-wow-duration="2s" href="javascript:void(0);" @click="download('windows')">
            <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/03.png')">
            <div>电脑端</div>
          </a>
          <a class="wow animate__zoomIn" data-wow-delay="100ms" data-wow-duration="2s" href="javascript:void(0);" @click="download('android')">
            <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/04.png')" alt="">
            <div>手机端</div>
          </a>
          <!--                <a href="https://itunes.apple.com/us/app/%E7%9B%8A%E5%B8%AE%E6%89%8B-%E6%96%B0%E7%89%88/id1458489949?l=zh&ls=1&mt=8">-->
          <!--                    <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/02.png')" alt="">-->
          <!--                    <div>iphone版</div>-->
          <!--                </a>-->
          <a class="wow animate__zoomIn" data-wow-delay="100ms" data-wow-duration="2s" href="javascript:void(0);" @click="download('zi-pei-driver')">
            <img class="p-wrap-img" v-lazy="require('../../../assets/images/function-download/05.png')" alt="" />
            <div>电话驱动</div>
          </a>
        </div>
        <el-dialog title="下载应用" :visible.sync="centerDialogVisible" width="30%" center>
          <div>如果30秒内下载无响应，请点击<a :href="downloadUrl">这里</a>手动下载！</div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="centerDialogVisible = false;">朕已知晓</el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 海报 -->
      <div class="main1">
        <div class="main1-t1">
          <div class="wow animate__fadeInLeft i-serial-number n-0" data-wow-delay="100ms" data-wow-duration="2s">
            <div class="n-c">
              <h1>桶装水配送</h1>
              <p>适用水厂、零售、批发同城配送终端业务，智能押回桶，智能数据纠错</p>
            </div>
            <b>01</b>
          </div>
          <div class="wow animate__fadeInLeft i-serial-number n-1" data-wow-delay="100ms" data-wow-duration="2s">
            <div class="n-c">
              <h1>液化气配送</h1>
              <p>适用气站、供应站同城配送终端业务，钢瓶智能追溯，智能押回瓶数据纠错</p>
            </div>
            <b>02</b>
          </div>
          <div class="wow animate__fadeInUpBig i-serial-number n-2" data-wow-delay="100ms" data-wow-duration="2s">
            <div class="n-c">
              <h1>生鲜店配送</h1>
              <p>当日下单次日配送，预售商城，统计预采购货品，灵活处理非包装品计量计价</p>
            </div>
            <b>03</b>
          </div>
          <div class="wow animate__fadeInRight i-serial-number n-3" data-wow-delay="100ms" data-wow-duration="2s">
            <div class="n-c">
              <h1>鲜奶配送</h1>
              <p>根据客户实际订单配送要求，自动建单派单，订单实时追踪及提醒客户出单信息</p>
            </div>
            <b>04</b>
          </div>
          <div class="wow animate__fadeInRight i-serial-number n-4" data-wow-delay="100ms" data-wow-duration="2s">
            <div class="n-c">
              <h1>社区小商超</h1>
              <p>综合线上业务，按照指定客户指定商品建单，智能计费收费，增加客户回头率</p>
            </div>
            <b>05</b>
          </div>
          <div class="wow animate__fadeInDownBig i-body" data-wow-delay="100ms" data-wow-duration="2s">
            <img v-lazy="require('../../../assets/images/k366/w.png')" height="67" width="63" />
            <span>多元行业热门趋势</span>
          </div>
        </div>
      </div>
      <!--多种设备完美支持-->
      <div class="bw bw-pic-1" data-wow-delay="100ms" data-wow-duration="2s">
        <div>
          <div class="bw-h">酷366版•能给您带来什么？</div>
          <div class="bw-a">『唯有享受便捷，轻松提高效益不再难事』</div>
        </div>
      </div>
      <div class="theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">多种设备完美支持</div>
            <div class="wow animate__bounceInUp theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「与时俱进，手机在手，天下我有」</div>
            <div class="wow animate__backInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">电脑管理端@移动手机端</div>
            <div class="wow animate__backInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">电脑管理端、手机APP配送端、微信客户端，三端互通</div>
            <div class="wow animate__backInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">管理端、配送端，接待客户，自动弹屏，智能匹配</div>
            <div class="wow animate__backInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">日常业务可随时随地的使用手机进行管理</div>
            <div class="theme-content-i-h"></div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/09.png')" height="50" width="51"/>
                <span>电脑版</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/10.png')" height="50" width="50"/>
                <span>手机版</span>
              </div>
            </div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <img class="wow animate__slideInRight" data-wow-delay="100ms" data-wow-duration="2s" v-lazy="require('../../../assets/images/function-download/08.png')" height="368" width="668" />
          </div>
        </div>
      </div>
      <!--微信小程序商城-->
      <div class="bw bw-pic-2" data-wow-delay="100ms" data-wow-duration="2s">
        <div>
          <div class="bw-h">酷366版，客户随时随地自助下单</div>
          <div class="bw-a">『减免与客户一对一接待，解决效率低问题』</div>
        </div>
      </div>
      <div class="theme">
        <div class="theme-box-left" style="height: 100%;">
          <div class="theme-content-left" style="width: 100%;height: 100%;position: relative">
            <img class="wow animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="2s" v-lazy="require('../../../assets/images/function-download/12.png')" style="position:absolute;right:5rem;bottom: 0" />
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">微信小程序商城</div>
            <div class="wow animate__lightSpeedInLeft theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「免安装，秒响应，更亲民，多元化」</div>
            <div class="wow animate__zoomIn theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">微信商城@自助下单</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">减免与客户一对一接待，解决效率低问题</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">多店铺切换，加盟店、直营店</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">财务独立，综合业务隔离，定位展示，推广绑定</div>
            <div class="wow animate__zoomIn theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">店面模板实时切换，营销活动节日庆典永不耽误</div>
            <div class="theme-content-i-h"></div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/13.png')"/>
                <span>手机支付</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/14.png')"/>
                <span>移动商城</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/15.png')"/>
                <span>营销工具</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--配送APP手机管理端-->
      <div class="bw bw-pic-3" data-wow-delay="100ms" data-wow-duration="2s">
        <div>
          <div class="bw-h">收支不成正比怎么办？</div>
          <div class="bw-a">『提高效率是王道、减少支出是根本』</div>
        </div>
      </div>
      <div class="theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">配送APP手机管理端</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「制度考勤、分派任务、绩效统计、路线规划」</div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">移动管理后台@日常业务处理</div>
            <div class="wow animate__slideInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">任务：配送任务明确，免沟通流水化处理，节省时间</div>
            <div class="wow animate__slideInLeft theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">目标：实时绩效统计，促进工作积极性提高整体效率</div>
            <div class="wow animate__slideInRight theme-content-p" data-wow-delay="100ms" data-wow-duration="2s">发展：前期业务转型，增产不加人减少人工支出成本</div>
            <div class="theme-content-i-h"></div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/18.png')"/>
                <span>手机管理</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/19.png')"/>
                <span>订单管理</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/20.png')"/>
                <span>配送管理</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/21.png')"/>
                <span>消息通知</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/22.png')"/>
                <span>营销工具</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/23.png')"/>
                <span>财务管理</span>
              </div>
            </div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <img data-wow-delay="100ms" data-wow-duration="2s" v-lazy="require('../../../assets/images/function-download/17.png')" style="margin-top: -4rem;"/>
          </div>
        </div>
      </div>
      <!--给您最好用的订单系统-->
      <div class="bw bw-pic-4" data-wow-delay="100ms" data-wow-duration="2s">
        <div>
          <div class="bw-h">一个转折一段路程一次选择</div>
          <div class="bw-a">『与君同行伴君左右』</div>
        </div>
      </div>
      <div class="theme">
        <div class="theme-box-left">
          <div class="theme-content-left" style="width: 100%">
            <img v-lazy="require('../../../assets/images/k366/pc-manage.png')" style="width: 100%;" />
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">给您最好用的订单系统</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「主控制端、界面鲜明、分权管理、自动升级」</div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">超级管理端@傻瓜式操作</div>
            <div class="wow animate__slideInRight theme-content-p">客户、订单、财务、进销存等，一目了然</div>
            <div class="wow animate__slideInRight theme-content-p">按照业务实际需求选择配置方案，提高人机优越体验</div>
            <div class="wow animate__slideInRight theme-content-p">订单自动分配，无异常订单无需人工分配</div>
            <div class="wow animate__slideInRight theme-content-p">好系统听众评，行业性通用功能永不收费</div>
            <div class="theme-content-i-h"></div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/26.png')"/>
                <span>手机支付</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/27.png')"/>
                <span>移动商城</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/28.png')"/>
                <span>营销工具</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--营销推广工具-->
      <div class="bw bw-pic-5" data-wow-delay="100ms" data-wow-duration="2s">
        <div>
          <div class="bw-h">酷366，是快捷</div>
          <div class="bw-a">省心的操作体验</div>
        </div>
      </div>
      <div class="theme">
        <div class="theme-box-left">
          <div class="theme-content-left">
            <div class="wow animate__bounceInDown theme-content-h" data-wow-delay="100ms" data-wow-duration="2s">营销推广工具</div>
            <div class="wow animate__zoomInDown theme-content-a" data-wow-delay="100ms" data-wow-duration="2s">「有效的组合与使用营销手段，客户还不是源源不断的来」</div>
            <div class="wow animate__slideInLeft theme-content-p active" data-wow-delay="100ms" data-wow-duration="2s">开拓市场@营销战略</div>
            <div class="wow animate__slideInRight theme-content-p">多元流量、快速获客、精准定位、提高转化</div>
            <div class="wow animate__slideInRight theme-content-p">灵活定制、刺激消费、提升单量、品牌曝光</div>
            <div class="theme-content-i-h"></div>
            <div class="theme-content-i">
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/30.png')"/>
                <span>获取新客</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/31.png')"/>
                <span>刺激消费</span>
              </div>
              <div class="wow animate__zoomIn theme-icon" data-wow-delay="100ms" data-wow-duration="2s">
                <img v-lazy="require('../../../assets/images/function-download/32.png')"/>
                <span>维系老客</span>
              </div>
            </div>
          </div>
        </div>
        <div class="theme-box-right">
          <div class="theme-content-right">
            <img class="main11-r-img" v-lazy="require('../../../assets/images/function-download/33.png')" />
          </div>
        </div>
      </div>
    </el-main>
    <el-footer height="auto">
      <Bottom></Bottom>
    </el-footer>
  </el-container>
</template>
<script>
    import Header from '@/components/Pc/Header';
    import Bottom from '@/components/Pc/Bottom';
    export default {
        name: "FunctionDownload",
        components: {Header, Bottom},
        data() {
          return {
              main2Position: 2,
              main4Position: 4,
              main6Position: 6,
              main8Position: 8,
              main10Position: 10,
              scroll: '',
              centerDialogVisible: false,
              downloadUrl: ''
          }
        },
        methods: {
            menu() {
                let scroll = document.documentElement.scrollTop || document.body.scrollTop;    // 离顶部距离
                let main2Position = this.main2Position + (this.scroll-scroll)/1000;
                let main4Position = this.main4Position + (this.scroll-scroll)/1000;
                let main6Position = this.main6Position + (this.scroll-scroll)/1000;
                let main8Position = this.main8Position + (this.scroll-scroll)/1000;
                let main10Position = this.main10Position + (this.scroll-scroll)/1000;
                this.scroll = scroll;
                this.main2Position = main2Position;
                this.main4Position = main4Position;
                this.main6Position = main6Position;
                this.main8Position = main8Position;
                this.main10Position = main10Position;
            },
            download(app){
              this.$axios.get(`https://shop.k366.top/api/dynamic@pda/v1.0.1/Auxiliary/Software/download?app=${app}`).then(async (r) => {
                this.centerDialogVisible =  (r['data']['data']['url'] || '').length > 0;
                this.downloadUrl = r['data']['data']['url'];
                // window.location.href = url;
                var divFrame = window.parent.document.getElementById("downLoadListFrame")
                //判断是否存在，如果存在先移除，再重新创建
                if (divFrame != null) {
                  window.parent.document.body.removeChild(divFrame)
                }
                //重新创建
                var iframe = window.parent.document.createElement("iframe");
                iframe.setAttribute("id", "downLoadListFrame");
                //download_file.id = "downFrame";
                window.parent.document.body.appendChild(iframe);
                divFrame = window.parent.document.getElementById("downLoadListFrame");
                //隐式调用，（注意：window.parent.document 适应对于，farme内部代码，调用frame 外部dom；如果项目未用frame，改写成 document即可）
                divFrame.src = this.downloadUrl;
                divFrame.style.display = "none";
              });
            }
        },
        created:function (){
            // this.brover()
        },
        mounted() {
          // window.addEventListener('scroll', this.menu);
          // eslint-disable-next-line no-console
          new this.$wow.WOW().init();
        },
    }
</script>
<style scoped>
    .el-header,.el-main,.el-footer{
      padding: 0;
    }
    .el-main {
      overflow: hidden;
    }
    .wow {visibility: hidden;}
    /* 海报 */
    .poster{
        background-image: url('../../../assets/images/function-download/01.jpg');
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        height: 48.75rem;
    }
    .poster-t1{
        font-size: 3.75rem;
        padding-top: 7.25rem;
        color: rgb(230, 198, 158);
      font-weight: bold;
        text-shadow: -1px -1px 0px rgba(251, 251, 251, 1), -2px -2px 0px rgba(251, 251, 251, 1);
    }
    .poster-t2{
        font-size: 2rem;
      font-weight: bold;
        padding-top: 1.56rem;
        color: rgb(43, 195, 172);
        text-shadow: -1px -1px 0px rgba(251, 251, 251, 1);
    }
    .poster-wrap{
        display: flex;
        padding-top: 3.69rem;
    }
    .poster-wrap>a{
        width: 9.25rem;
        height: 2.69rem;
        background-color: rgba(83, 80, 103, 0.8);
        font-size: 1.13rem;
        display: flex;
        align-items: center;
        margin: 0 1rem;
        cursor: pointer;
        color: #fff;
    }
    .p-wrap-img{
        width: 1.63rem;
        height: 1.63rem;
        margin: 0 .69rem;
    }

    .main1{
        min-height: 388px;
        background: url("../../../assets/images/function-download/06.png") center;
        text-align: center;
        overflow: hidden;
        padding: 32px 0;
    }

    .main1-t1{
      padding-top: 1rem;
      width: 235px;
      height: 167px;
      background: url("../../../assets/images/k366/e.png") no-repeat center;
      margin: 0 auto;
      position: relative;
    }
    .i-body{
      text-align: center;
    }
    .i-body>span{
      display: block;
      font-size: 20px;
      font-weight: bold;
      color: rgb(43, 195, 172);
    }
    .i-serial-number{
      position: absolute;
      width: 73px;
      height: 71px;
      padding: 0 32px;
    }
    .i-serial-number>b{
      display: block;
      width: 100%;
      line-height: 73px;
      text-align: center;
      font-size: 28px;
      color: rgb(43, 195, 172);
    }
    .i-serial-number .n-c{
      width: 314px;
      position: absolute;
    }
    .i-serial-number .n-c>h1{
      font-size: 20px;
      margin-bottom: 8px;
      color: rgb(43, 195, 172);
    }
    .i-serial-number .n-c>p{
      font-size: 14px;
      color: rgb(153, 153, 153);
      line-height: 20px;
    }
    /*0*/
    .i-serial-number.n-0 {
      left: -132px;
      top: 1rem;
      text-align: right;
    }
    .i-serial-number.n-0::before{
      content: "";
      width: 73px;
      height: 71px;
      background: url("../../../assets/images/k366/c.png") no-repeat center;
      position: absolute;
      top: 0;
      left: 32px;
    }
    .i-serial-number.n-0 .n-c{
      left: -314px;
      top: 0;
    }
    /*1*/
    .i-serial-number.n-1 {
      left: -98px;
      top: 140px;
      text-align: right;
    }
    .i-serial-number.n-1::before{
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/k366/c.png") no-repeat center;
      position: absolute;
      top: 0;
      left: 0;
      transform:rotate(-45deg);
    }
    .i-serial-number.n-1 .n-c{
      left: -314px;
      top: 0;
    }
    /*2*/
    .i-serial-number.n-2 {
      left: 81px;
      bottom: -128px;
      padding: 32px 0;
      height: auto;
    }
    .i-serial-number.n-2::before{
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/k366/c.png") no-repeat center;
      position: absolute;
      top: 0;
      left: 0;
      transform:rotate(-145deg);
    }
    .i-serial-number.n-2 .n-c{
      left: -120.5px;
      top: 135px;
    }
    /*3*/
    .i-serial-number.n-3 {
      right: -98px;
      top: 140px;
      text-align: left;
    }
    .i-serial-number.n-3::before{
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/k366/c.png") no-repeat center;
      position: absolute;
      top: 0;
      left: 0;
      transform:rotate(90deg);
    }
    .i-serial-number.n-3 .n-c{
      right: -314px;
      top: 0;
    }
    /*4*/
    .i-serial-number.n-4 {
      right: -132px;
      top: 1rem;
      text-align: left;
    }
    .i-serial-number.n-4::before{
      content: "";
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/k366/c.png") no-repeat center;
      position: absolute;
      top: 0;
      left: 0;
      transform:rotate(45deg);
    }
    .i-serial-number.n-4 .n-c{
      right: -314px;
      top: 0;
    }
    /*--------------*/
    .bw {
      background-image: none;
      background-attachment: fixed;
      background-size: cover;
      background-position: center center;
      /*flex 布局*/
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      /*实现水平居中*/
      justify-content: center;
      text-align: justify;
      height: 37.5rem;
      overflow: hidden;
      font-size: 2.19rem;
      letter-spacing: 0.44rem;
      color: rgb(43, 195, 172);
    }
    .bw .bw-h, .bw .bw-a{
      text-align: center;
      text-shadow: -1px -1px 0px rgba(251, 251, 251, 1);
    }
    .bw.bw-pic-1 {
      background-image: url("../../../assets/images/function-download/07.jpg");
    }
    .bw.bw-pic-2 {
      background-image: url("../../../assets/images/function-download/16.jpg");
    }
    .bw.bw-pic-3 {
      background-image: url("../../../assets/images/function-download/11.jpg");
    }
    .bw.bw-pic-4 {
      background-image: url("../../../assets/images/function-download/24.jpg");
    }
    .bw.bw-pic-5 {
      background-image: url("../../../assets/images/function-download/29.jpg");
    }
    .theme {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 37.5rem;
      padding: 0 10%;
    }
    .theme .theme-box-left {
      flex: 0 0 50%;
      height: 23.25rem;
    }
    .theme .theme-box-left .theme-content-left {
      float: right;
      margin-right: 1rem;
      width: auto;
    }
    .theme .theme-box-right {
      flex: 0 0 50%;
      height: 23.25rem;
    }
    .theme .theme-box-right .theme-content-right {
      margin-left: 1rem;
      float: left;
    }
    .theme .theme-content-h {
      width: 100%;
      text-align: center;
      font-size: 2.5rem;
      /*letter-spacing: 0.43rem;*/
      color: #404040;
    }
    .theme .theme-content-a {
      margin-top: 1.25rem;
      width: 100%;
      text-align: center;
      font-size: 1rem;
      /*letter-spacing: 0.09rem;*/
      color: #707070;
    }
    .theme .theme-content-p {
      margin-top: 1rem;
      font-size: 1rem;
      /*letter-spacing: 0.19rem;*/
      color: #404040;
    }
    .theme .theme-content-p.active {
      margin-top: 2rem;
      font-weight: bold;
    }
    .theme .theme-content-i-h {
      width: 3.1875rem;
      height: 0.13rem;
      background-image: linear-gradient(90deg, #0e8ef7 0%, #257aeb 50%, #3c66de 100%), linear-gradient( #29aefb, #29aefb);
      background-blend-mode: normal, normal;
      opacity: 0.99;
      margin-left: 1.0625rem;
      margin-top: 2rem;
    }
    .theme .theme-content-i {
      margin-top: 1.44rem;
      display: flex;
      font-size: 0.75rem;
      /*letter-spacing: 0.16rem;*/
      color: #8e8d8d;
    }
    .theme .theme-content-i .theme-icon {
      text-align: center;
      margin: auto .3125rem;
      width: 5rem;
      height: auto;
    }
    .theme .theme-content-i .theme-icon>img {
      width: 3.1875rem;
      height: 3.125rem;
    }
    .theme .theme-content-i .theme-icon>span {
      display: block;
      text-align: center;
    }
    /*-----------------------*/
</style>
