<template>
    <div :class="{mobile: isMobile}" style="width: 100%">
        <div class="main" v-if="csc['mainIsShow']">
            <div class="m-wrap" :style="{display: isShow ? 'flex': 'none'}" @mouseover='custerMouseover()' @mouseleave='custerMouseleave()'>
                <img class="m-wrap-img" v-lazy="require('../../assets/images/customer-service/c1.png')" alt="" />
                <div class="m-wp-item">
                    <div style="margin-left: 15%">
                      <div class="m-wp-item-t1">
                        <img class="m-wp-item-t1-img" v-lazy="require('../../assets/images/customer-service/03.png')" alt="">
                        <span class="m-wp-item-t1-span">在线客服</span>
                      </div>
                      <div v-for="(item, i) in customerQrdImg" style="width: 100%">
                        <div class="m-wp-item-t2">{{item['occupation']}}:{{item['name']}}</div>
                        <div class="m-wp-item-t3" @click="advisoryService(i)">{{item['action']}}</div>
                      </div>
                      <div class="m-wp-item-t6">
                        <img class="m-wp-item-t6-img" v-lazy="require('../../assets/images/customer-service/04.png')" alt="" />
                        <span class="m-wp-item-t6-span">微信公众号</span>
                      </div>
                      <div class="m-wp-item-t7">
                        <img v-lazy="weChatPublicPlatform['img']" alt="" />
                      </div>
                    </div>
                </div>
                <img class="m-wrap-img" v-lazy="require('../../assets/images/customer-service/c3.png')" alt="" />
            </div>
            <img class="customer-service" @mouseover='custerMouseover()' @mouseleave='custerMouseleave()' v-lazy="require('../../assets/images/customer-service/02.png')" alt="">
        </div>
        <!-- 弹窗 -->
        <div class="show-qrcode">
            <el-dialog :visible.sync="dialogVisible">
                <div class="qrcode-t">
                    <div class="qrcode-t-w1">
                        <img v-lazy="require('../../assets/images/k366/logo.png')" height="34" />
                        <img @click="dialogVisible=false" v-lazy="require('../../assets/images/function-download/37.png')" height="20" width="20"/>
                    </div>
                    <div class="qrcode-t-w2">{{customerQrdImg[customerQrdImgIndex].occupation}}：{{customerQrdImg[customerQrdImgIndex].name}}</div>
                    <div class="qrcode-t-w3">
                        <img v-lazy="customerQrdImg[customerQrdImgIndex].img" width="204" />
                    </div>
                    <div class="qrcode-t-w4">
                        <img v-lazy="require('../../assets/images/function-download/39.png')" height="29" width="30" />
                        <span>微信扫码加好友</span>
                    </div>
                </div>
                <div class="qrcode-b" v-if="customerQrdImg[customerQrdImgIndex].phone">
                    联系电话：{{customerQrdImg[customerQrdImgIndex].phone}}
                    <br/>
                    添加{{customerQrdImg[customerQrdImgIndex].name}}微信号：{{customerQrdImg[customerQrdImgIndex].phone}} 了解疑问
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomerService",
        props: {
          csc: {
            type: Object,
            default: function(){
              return {mainIsShow: true};
            }
          },
          isMobile: {
            type: Boolean,
            default: false
          }
        },
        data() {
            return{
                isShow: false,
                customerQrdImg:[  // 客服联系二维码
                    {
                        occupation: '客户经理',
                        name: '小刘',
                        img: require('../../assets/images/k366/xiao-liu.png'),
                        phone: '',
                        action: '联系小刘',
                        type: 1
                    },
                    {
                        occupation: '客户经理',
                        name: '小梅',
                        img: require('../../assets/images/k366/xiao-mei.png'),
                        phone: '',
                        action: '联系小梅',
                        type: 1
                    },
                    {
                        occupation: '技术顾问',
                        name: '张工',
                        img: require('../../assets/images/k366/zhang-gong.png'),
                        phone: '18959731860',
                        action: '联系张工',
                        type: 2
                    },
                ],
                customerQrdImgIndex: 0,   // 客服联系二维码下标
                dialogVisible: false,
                weChatPublicPlatform: {
                  img: require('../../assets/images/k366/gong-zhong-hao.jpg')
                }
            }
        },
        created: function() {
        },
        methods:{

            /**
             * 鼠标移出
             */
            custerMouseleave() {
                this.isShow = false;
                // this.$emit('FatherIsShow', false)
            },

            /**
             * 鼠标移入
             */
            custerMouseover(){
                this.isShow = true;
                // this.$emit('FatherIsShow', true)
            },

            advisoryService(i){
                this.customerQrdImgIndex = i;
                this.dialogVisible = true
            }
        }
    }
</script>

<style scoped>
    .main{
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 100;
        text-align: right;
    }
    /*隐藏展现部分*/
    .main .m-wrap{
        position: absolute;
        bottom: 5.25rem;
        right: -0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        width: 15.4375rem;
        height: auto;
    }
    .main .m-wrap .m-wrap-img{
        width: 15.4375rem;
        height: 1.6875rem;
    }
    .main .m-wrap .m-wp-item{
      width: 100%;
      background-repeat: repeat-y;
      background-image: url("../../assets/images/customer-service/c2.png");
    }
    .main .m-wrap .m-wp-item .m-wp-item-t1,.main .m-wrap .m-wp-item .m-wp-item-t6{
        display: flex;
        align-items: center;
        font-size: 0.94rem;
        letter-spacing: 0.05rem;
        color: #000000;
        font-weight: bold;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t6{
        margin-top: 1rem;
        margin-right: 3rem;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t1 .m-wp-item-t1-img,.m-wp-item-t6-img{
        width: 1.44rem;
        height: 1.44rem;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t1 .m-wp-item-t1-span,.m-wp-item-t6-span{
        margin-left: .75rem;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t2{
        font-size: 0.88rem;
        letter-spacing: 0.04rem;
        color: #404040;
        margin: 0.3rem 0 0 2.2rem;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t3{
        width: 4.81rem;
        height: 1.5rem;
        background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
        background-blend-mode: normal, normal;
        border-radius: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.81rem;
        letter-spacing: 0.04rem;
        color: #ffffff;
        margin: 0.3rem 0 0 2.2rem;
        cursor: pointer;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t7{
        margin: 0.3rem 0 0 1.8rem;
    }
    .main .m-wrap .m-wp-item .m-wp-item-t7>img{
        width: 6.88rem;
        height: 6.88rem;
    }
    .customer-service{
        width: 5.25rem;
        height: 5.25rem;
        cursor: pointer;
    }
    /* 弹窗 */
    .show-qrcode>>>.el-dialog{
      width:27.81rem;
      border-radius: 1.5rem;
    }
    .show-qrcode>>>.el-dialog__body{
        padding: 0;
    }
    .show-qrcode>>>.el-dialog__header{
        display: none;
    }

    .qrcode-t{
        padding: 30px 20px;
        height: auto;
    }
    .qrcode-t-w1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .qrcode-t-w2{
        width: 17.94rem;
        height: 2.13rem;
        background-color: #f7f7f7;
        border-radius: 1.06rem;
        font-size: 1.3rem;
        line-height: 2.13rem;
        color: #404040;
        margin: 1.91rem auto 0;
        text-align: center;
    }
    .qrcode-t-w3{
        text-align: center;
        margin-top: 1rem;
    }
    .qrcode-t-w4{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.38rem;
        letter-spacing: 0.07rem;
        color: #404040;
    }
    .qrcode-t-w4>span{
        margin-left: 1rem;
    }
    .qrcode-b{
        padding: 1rem 0;
        font-size: 1.3rem;
        background-color: #f7f7f7;
        color: #404040;
        border-radius: 0 0 1.5rem 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* 弹窗 */
    .mobile .main{
      bottom: 3rem;
      right: 0;
    }
    .mobile .main .m-wrap{
      bottom: 2.25rem;
      right: -0.3rem;
      width: 7.71875rem;
    }
    .mobile .main .m-wrap .m-wrap-img{
      width: 100%;
      height: auto;
    }
    .mobile .main .m-wrap .m-wp-item{
      background-size: cover;
    }

    .mobile .main .m-wrap .m-wp-item .m-wp-item-t1, .mobile .main .m-wrap .m-wp-item .m-wp-item-t6{
      font-size: 0.47rem;
      letter-spacing: 0.05rem;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t6{
      margin-top: 0.5rem;
      margin-right: 0;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t1 .m-wp-item-t1-img, .mobile .main .m-wrap .m-wp-item .m-wp-item-t6 .m-wp-item-t6-img{
      width: 0.72rem;
      height: 0.72rem;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t1 .m-wp-item-t1-span, .mobile .main .m-wrap .m-wp-item .m-wp-item-t6 .m-wp-item-t6-span{
      margin-left: 0.375rem;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t2{
      font-size: 0.44rem;
      letter-spacing: 0.02rem;
      color: #404040;
      margin: 0.15rem 0 0 1.1rem;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t3{
      width: 2.405rem;
      height: 0.75rem;
      background-image: linear-gradient(91deg, #46a1e8 0%, #4683ed 50%, #4667f2 100%), linear-gradient(#e5e5e5, #e5e5e5);
      background-blend-mode: normal, normal;
      border-radius: 0.375rem;
      text-align: center;
      line-height: 0.75rem;
      font-size:0.405rem;
      letter-spacing: 0.02rem;
      color: #ffffff;
      margin: 0.15rem 0 0 1.1rem;
      cursor: pointer;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t7{
      margin: 0.15rem 0 0 0.9rem;
    }
    .mobile .main .m-wrap .m-wp-item .m-wp-item-t7>img{
      width: 3.44rem;
      height: 3.44rem;
    }
    .mobile .main .customer-service{
      width: 2.625rem;
      height: 2.625rem;
    }
    .mobile .show-qrcode>>>.el-dialog{
      width: 90vw;
      border-radius: 0.725rem;
    }
    .mobile .qrcode-t-w3{
      margin-top: 0.5rem;
    }
    .mobile .qrcode-t-w2{
      width: 90%;
      height: auto;
      background-color: #f7f7f7;
      border-radius: 0.5rem;
      font-size: 0.6rem;
      line-height: 1rem;
      color: #404040;
      margin: 0.9rem auto 0;
      text-align: center;
    }
    .mobile .qrcode-t-w4{

      font-size: 0.5rem;
      letter-spacing: 0.03rem;

    }
    .mobile .qrcode-t-w4>span{
      margin-left: 0.5rem;
    }
    .mobile .qrcode-b{
      height: auto;
      padding: 0.5rem 0;
      font-size: 0.45rem;
      border-radius: 0 0 0.725rem 0.725rem;
    }
</style>
