<template>
    <div>
        <Header></Header>
        <div class="main1">
            <div class="m1-t1">酷366生态合作伙伴，全国火热招商中</div>
            <div class="m1-t2">致力于与合作伙伴打造最新风口，携手共掘酷366万亿市场</div>
            <div class="m1-t3">加入我们</div>
        </div>

        <Horselight :cTitle="'优质合作伙伴'"></Horselight>

        <div class="main3">
            <div class="m3-t1">为什么要成为酷366合作伙伴</div>
            <div class="m3-wrap">
                <div class="m3-wrap-one">
                    <img class="m3wp-one-img" v-lazy="require('../../../assets/images/659.png')" alt="">
                    <div class="m3wp-one-t1">海量资源</div>
                </div>
                <div class="m3-wrap-one">
                    <img class="m3wp-one-img" v-lazy="require('../../../assets/images/657.png') " alt="">
                    <div class="m3wp-one-t1">丰厚返利</div>
                </div>
                <div class="m3-wrap-one">
                    <img class="m3wp-one-img" v-lazy="require('../../../assets/images/658.png') " alt="">
                    <div class="m3wp-one-t1">市场巨大</div>
                </div>
                <div class="m3-wrap-one">
                    <img class="m3wp-one-img" v-lazy="require('../../../assets/images/660.png') " alt="">
                    <div class="m3wp-one-t1">培训支持</div>
                </div>
            </div>
        </div>

        <div class="main4">
            <div class="m4-t1">成为合作伙伴的条件</div>
            <div class="m4-wrap" v-for="item of CooperationCondition">
                <div class="m4-wp-l"></div>
                <div class="m4-wp-r">
                    <div class="m4-wpr-t1">{{item.title}}</div>
                    <div class="m4-wpr-t2">{{item.content}}</div>
                </div>
            </div>
        </div>

        <div class="main5">
            <div class="m5-t1">合作伙伴的申请流程</div>
            <div class="m5-wrap">
                <div class="m5-wp-one">
                    <img class="m5-wpone-img" v-lazy="require('../../../assets/images/1.png')" alt="">
                    <span class="m5-wpone-t1">注册/登录</span>
                </div>
                <div class="m5-wp-one">
                    <img class="m5-wpone-img" v-lazy="require('../../../assets/images/2.png')" alt="">
                    <span class="m5-wpone-t1">提交申请</span>
                </div>
                <div class="m5-wp-one">
                    <img class="m5-wpone-img" v-lazy="require('../../../assets/images/3.png')" alt="">
                    <span class="m5-wpone-t1">合作洽谈</span>
                </div>

            </div>
            <div class="m5-wrap m5-wrap-copy">
                <div class="m5-wp-one">
                    <img class="m5-wpone-img" v-lazy="require('../../../assets/images/4.png')" alt="">
                    <span class="m5-wpone-t1">预存款项</span>
                </div>
                <div class="m5-wp-one">
                    <img class="m5-wpone-img" v-lazy="require('../../../assets/images/5.png')" alt="">
                    <span class="m5-wpone-t1">签订合作协议</span>
                </div>
            </div>
            <div class="m5-bottom">立即申请</div>
        </div>
        <CustomerService></CustomerService>
        <Bottom></Bottom>
    </div>
</template>

<script>
    import Bottom from '@/components/Mobile/Bottom';
    import Header from '@/components/Mobile/Header';
    import CustomerService from '@/components/Mobile/CustomerService';
    import Horselight from '@/components/Mobile/Horselight';

    export default {
        name: "AgentJoining",
        components:{
            Header,
            Bottom,
            Horselight,
            CustomerService
        },
        data(){
            return{
                CooperationCondition:[
                    {
                        title:'具备公司资质',
                        content:'具有合法营业执照和独立代表，有良好的商业信誉和口碑'
                    },
                    {
                        title:'具有销售团队',
                        content:'专职销售团队，具有互联网或相关行业的代理或销售经营背景 '
                    },
                    {
                        title:'具有客户资源',
                        content:'拥有当地丰富的企业级资源或线下门店资源'
                    },{
                        title:'认可双宝网络文化',
                        content:'认同双宝网络的产品价值和文化理念，愿意跟双宝网络长期合作'
                    },

                ]
            }
        },
    }
</script>

<style scoped>
    .main1{
        width: 100%;
        min-height: 5rem;
        background: url("../../../assets/images/agent.jpg") center;
        background-size: auto 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2.281rem 0;
    }
    .m1-t1{
        font-size: 0.688rem;
        width: 70%;
    }
    .m1-t2{
        font-size: 0.438rem;
        width: 80%;
        margin-top: 0.625em;
    }
    .m1-t3{
        width: 4.609rem;
        height: 1.266rem;
        line-height: 1.266rem;
        font-size: 0.531rem;
        background-image: linear-gradient(-45deg, #5e7cff 0%, #6f96fe 50%, #80affc 100%),
        linear-gradient(#ffffff, #ffffff);box-shadow: 0rem 0rem 0rem 0rem rgba(132, 140, 176, 0.63);
        margin-top: 1.625rem;
    }

    .main3{
        width: 100%;
        min-height: 5rem;
        background-color: #f1f7fc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2.094rem 0;
    }
    .m3-t1{
        font-size: 0.688rem;
        color: #404040;
    }
    .m3-wrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .m3-wrap-one{
        text-align: center;
        margin-top: 1.469rem;
    }
    .m3wp-one-img{

    }
    .m3wp-one-t1{
        font-size: 0.455rem;
        letter-spacing: 0.023rem;
        color: #404040;
        font-weight: bold;
        margin-top: 1.125rem;
    }
    .main4{
        width: 100%;
        min-height: 5rem;
        padding: 1.734rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .m4-t1{
        font-size: 0.688rem;
        color: #404040;
        margin-bottom: 0.468rem;
    }
    .m4-wrap{
        width: 82%;
        position: relative;
        margin-top: 1.156rem;
    }
    .m4-wp-l{
        width: 100%;
        height: 6.016rem;
        background-color: #3f9dfd;
        opacity: 0.25;
        position: absolute;
        bottom: 0.235rem;
        left: 0.235rem;
    }
    .m4-wp-r{
        height: 6.016rem;
        background: #fff;
        position: relative;
        top: 0.235rem;
        right: 0.235rem;
        z-index: 5;
        box-shadow: 0rem 0rem 0.141rem 0rem rgba(10, 27, 81, 0.13);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .m4-wpr-t1{
        font-size: 0.563rem;
        letter-spacing: 0.028rem;
        color: #3f9dfd;
        padding: 0 1.063rem;
        font-weight: bold;
    }
    .m4-wpr-t2{
        font-size: 0.5rem;
        color: #404040;
        padding: 0 1.063rem;
        margin-top: 0.734rem;
    }
    .main5{
        padding: 0 0.641rem;
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .m5-t1{
        font-size: 0.688rem;
        color: #404040;
        font-weight: bold;
    }
    .m5-wrap{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .m5-wrap-copy{
        justify-content: space-evenly;
    }

    .m5-wp-one{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.469rem;
    }
    .m5-wpone-img{
        width: 0.891rem;
        height: 0.875rem;
    }
    .m5-wpone-t1{
        font-size: 0.418rem;
        color: #3f83ff;
        margin-top: .391rem;
    }
    .m5-bottom{
        width: 2.953rem;
        height: 1.078rem;
        line-height: 1.078rem;
        text-align: center;
        background-color: #3f9dfd;
        border-radius: 0.047rem;
        font-size: 0.438rem;
        color: #ffffff;
        margin-top: 1.5rem ;
    }
</style>
