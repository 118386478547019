<template>
    <div>
        <div class="main13">
            <div class="m13-t1">双宝新零售配送管理系统</div>
            <div class="m13-t2">已为您准备好，请下载体验</div>
            <div class="m13-t3" @click="DownloadAndCustomerServiceIsShow=true">下载酷366版</div>
        </div>
        <div class="bottom">
            <div class="bm-t">联系我们</div>
            <div class="bm-m">
                <div class="bm-m-l" v-for="(item, index) in customerQrdImg" @click="contactUs(index)">
                    <span class="bm-ml-t1">{{item['occupation']}}：{{item.name}}</span>
                    <img class="bm-ml-img" v-lazy="require('../../../assets/images/mobile/24.png')" alt="" />
                </div>
            </div>
            <div class="bm-b">
<!--                <div class="bm-b-t1">咨询热线：<a class="bm-b-t1" href="tel:0592-5793363‬">0592-5793363</a></div>-->
                <div class="bm-b-t2">泉州市双宝网络技术有限公司</div>
                <div class="bm-b-t3">泉州市茶博汇隆恩公馆11号楼111号</div>
                <div class="btm-l-t3">@2021 双宝网络·
                    <a class = "beianhao" rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank" style = "color: #ffffff">闽ICP备18012019号-1</a>
                </div>
            </div>
        </div>
        <CustomerService ref="cs" :isMobile="true"></CustomerService>
        <downloadAndCustomerService
            v-on:DownloadAndCustomerService="closeDownloadAndCustomerService"
            :customerService="customerQrdImg"
            :advisoryService="contactUs"
            :isShow="DownloadAndCustomerServiceIsShow">
        </downloadAndCustomerService>
    </div>
</template>

<script>
    import CustomerService from '@/components/CustomerService';
    import downloadAndCustomerService from '@/components/Mobile/DownloadAndCustomerService';
    export default {
        name: "Bottom",
        components:{
            CustomerService,
            downloadAndCustomerService
        },
        data(){
            return{
                DownloadAndCustomerServiceIsShow: false,
                weChatPublicPlatform: null,
                customerQrdImg: [],
            }
        },
        created: function() {
        },
        methods:{

            // 关闭客服与下载弹窗
            closeDownloadAndCustomerService(val){
                this.DownloadAndCustomerServiceIsShow = val;
            },
            /**
             * 显示客服二维码
             * @param val
             */
            contactUs(val){
              return this.$refs['cs'].advisoryService(val);
            },
        },
        mounted() {
          this.customerQrdImg = [...this.$refs['cs'].customerQrdImg];
          this.weChatPublicPlatform = {...this.$refs['cs'].weChatPublicPlatform};
        }
    }
</script>

<style scoped>
    .main13{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
    }
    .m13-t1{
        font-size: 0.688rem;
        font-weight: bold;
        color: #404040;
        margin-top: 1.031rem;
    }
    .m13-t2{
        font-size: 0.438rem;
        color: #707070;
        text-align: center;
        margin-top: 0.781rem;
    }
    .m13-t3{
        width: 4.609rem;
        height: 1.266rem;
        background: url("../../../assets/images/mobile/23.png");
        background-size: 100% 100%;
        line-height: 1.266rem;
        font-size: 0.531rem;
        color: #ffffff;
        text-align: center;
        margin: 1.031rem 0;
    }
    .bottom{
        width: 100%;
        background-color: #434343;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.672rem 0 0.969rem 0;
    }
    .bm-t{
        font-size: .5rem;
        letter-spacing: 0.04rem;
        color: #ffffff;
    }
    .bm-m{
        width: 88%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .bm-m-l{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .563rem;
    }
    .bm-ml-t1{
        font-size: 0.438rem;
        color: #ffffff;
    }
    .bm-ml-img{
        width: 0.75rem;
        height: 0.75rem;
        margin-left: .297rem;
    }
    .bm-b{
        width: 93%;
        height: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-top: 1px solid #dedede;
        margin-top: 1.078rem;
        padding-top: 0.438rem;
    }
    .bm-b>div{
        font-size: 0.406rem;
        letter-spacing: 0.016rem;
        margin-top: 0.234rem;
    }
    .bm-b-t1{
        color: #fff;
    }
    .beianhao:hover{
        text-decoration: underline;
    }
</style>
